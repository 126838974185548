import React, {useEffect, useState} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Overlay from "../Components/Overlay";
import {Checker} from "../Queries/Sessions"
import Chart from "react-apexcharts";
import API from "../Queries/API";
import useLessons from "../DataFiles/Lessons";
import useCourses from "../DataFiles/Courses";
import Courses from "../DataFiles/Courses";
import jAlert from "../Helpers/jAlert";
import Faqs from "../Components/faqs"
import $ from 'jquery';
import moment from "moment";
import {dateDiff} from "../Helpers/dateHelper";
import {lessonHelper} from "../Helpers/lessonHelpers";
import alertify from "alertifyjs";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import VideoBanner from "../Assets/Images/VideoBanner.png";





const Dashboard = () => {
    const {data:Lessons, isLoading, error} = useLessons()
    const {data:Courses, isLoadingCourse, courseError} = useCourses()


    var formatSecs = (secs) => {
        var sec_num = parseInt(secs, 10)
        var hours   = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        if(isNaN(secs))
            return false;

        return [hours,minutes,seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")+" s"
    }


    //OPTIONS FOR DASHBOARD UI

    const [showMenu, setShowMenu] = useState(false)
    useEffect(()=>{
        // console.log("ss")
        Checker()
    },[])
    const [options, setOptions] = useState({
        /*        series: [{
                    name: "Views",
                    data: [450, 650, 440, 160, 940, 414, 555, 257]
                },{
                    name: "Clicks",
                    data: [580, 350, 760, 350, 850, 352, 785, 241]
                }],*/
        chart: {
            foreColor: '#9a9797',
            type: "bar",
            //width: 130,
            height: 280,
            toolbar: {
                show: !1
            },
            zoom: {
                enabled: !1
            },
            dropShadow: {
                enabled: 0,
                top: 3,
                left: 14,
                blur: 4,
                opacity: .12,
                color: "#3361ff"
            },
            sparkline: {
                enabled: !1
            }
        },
        markers: {
            size: 0,
            colors: ["#3361ff"],
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7
            }
        },
        grid: {
            show: true,
            borderColor: '#eee',
            strokeDashArray: 4,
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: "50%",
                endingShape: "rounded"
            }
        },
        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
            offsetX: -20
        },
        dataLabels: {
            enabled: !1
        },
        stroke: {
            colors: ["transparent"],
            show: !0,
            width: 5,
            curve: "smooth"
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: ["#005bea", "#ff0080"],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                //stops: [0, 50, 100],
                //colorStops: []
            }
        },
        colors: ["#348bff", "#ff00ab"],
        xaxis: {
            categories: ["Module1", "Module2", "Module3", "Module4", "Module5", "Module6", "Module7", "Module8", "Module9", "Module10"]
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function (val) {
                    return "" + val + ""
                }
            }
        }
    })
    const [series, setSeries] = useState([{
        name: "Views",
        data: [450, 650, 440, 160, 940, 414, 555, 257]
    },{
        name: "Clicks",
        data: [580, 350, 760, 350, 850, 352, 785, 241]
    }])
    const [vChildren, setChildren] = useState([])
    const [vTitle, setVTitle] = useState("")
    const [openTab, setOpenTab] = useState(null)
    const [keya, setKeya] = useState("RXJyb3I=");
    const [keyb, setKeyb] = useState("RXJyb3I=");


    //OPTIONS FOR COURSE TABLES
    const [myResult, setMR] = useState({})
    const [hasSurvey, setHasSurvey] = useState(false)
    const [ResultDetails, setRD] = useState([])
    const [Meetings, setMeetings] = useState(null)
/*
    useEffect(()=>{
        console.log({mc:Meetings})
    },[Meetings])
*/

    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'getscores',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({serverProgress:result})
                setMR(result?.data)
                console.log({RD:result?.detail})
                setRD(result?.detail)
                setHasSurvey(result?.survey?.length > 0)
            })
            .catch(error => console.log('error', error));


    },[])


    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.usertoken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(API.meeting, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log({result})
                if(result?.meetingData?.length){
                     setMeetings(result?.meetingData?.[0])
                }
            })
            .catch(error => console.log('error', error));
    },[])


    useEffect(()=>{
        let uInfo = JSON?.parse(localStorage?.userData)
        console.log({uInfo})
        setKeya(base64_encode(uInfo?.full_name))


        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "stateId": uInfo?.state_id,
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(API.getDistrict, requestOptions)
            .then(response => response.json())
            .then(result => {
                   let dd = result?.district?.find((oneD)=>{
                    return oneD?.district_id == uInfo?.district_id
                })
                dd && setKeyb(base64_encode(dd?.district_name));
            })
            .catch(error => console.log('error', error))
            // .finally(next && next());

    },[])

    const calcTime = (progressboard)=>{
        console.log({progressboard})
        let totalTime = 0;
        if(progressboard != undefined){
            progressboard = JSON.parse(progressboard);
            for (const [key, value] of Object.entries(progressboard)) {
                totalTime += value;
                // console.log(`${key}: ${value}`);
            }
        }


        console.log({totalTime})
        return formatSecs(totalTime)
        // return new Date(totalTime * 1000).toISOString().substring(11, 16)


    }
    const topicTime = (x)=>{
        let mrjson = JSON.parse(myResult?.progressboard || "{}")
        // console.log({mrjson})
        // debugger;
        return formatSecs(mrjson[x+1])
    }
    const isOngoing = (Y)=>{
        //get pending
        let pend = Y?.find((one)=>{
            return topicTime(one.lockId)
        })

        //get completed
        let comp = Y?.find((one)=>{
            return !topicTime(one.lockId)
        })

        //return pending and completed ?
        return pend && comp;

    }
    const getTiming=(x)=>{
        const curPrg = myResult?.progress
        const T =  lessonHelper.mapLockId(Lessons)[x]

        if(curPrg > T.maxLock){
            return "Completed";
        }

        if(curPrg >= T.minLock && curPrg <= T.maxLock){
            return "On-Going";
        }
        // debugger;
        if(curPrg < T.maxLock){
            return "Pending";
        }
        return "Pending";

    }

    const getTimingOLD = (g)=>{
        let prg = myResult?.progress
        switch(g){
            case 0:
                if(prg > -1 && prg < 17)
                    return "On-Going";

                if(prg > 16)
                    return "Completed"

                return "Pending"
                break;
            case 1:

                if(prg > 17 && prg < 28)
                    return "On-Going";

                if(prg > 28)
                    return "Completed"

                return "Pending"

                break;
            default:
                return "Pending";
        }


    }




    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default ">

                <div className="preloader">
                    <div className="sk-double-bounce">
                        <div className="sk-child sk-double-bounce1"></div>
                        <div className="sk-child sk-double-bounce2"></div>
                    </div>
                </div>

                <div className="mdk-header-layout js-mdk-header-layout">

                    <Header showMenu={setShowMenu}/>


                    <div className="mdk-header-layout__content page-content ">


                        <div className="pt-32pt">
                            <div
                                className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                                <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">

                                    <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                        <h2 className="mb-0">Dashboard</h2>

                                        <ol className="breadcrumb p-0 m-0">
                                            <li className="breadcrumb-item"><a href="">Home</a></li>

                                            <li className="breadcrumb-item active">

                                                Dashboard

                                            </li>

                                        </ol>

                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="container page__container">
                            <div className="page-section">

                                <div className="col-md-6">
                                    <select id="custom-select" className="form-control custom-select">
                                        <option>Select Course</option>
                                        {
                                            Courses?.map((oneCourse)=>{
                                                return (<option selected value={oneCourse?.id}>{oneCourse?.Title}</option>)
                                            })
                                        }
                                    </select>
                                </div>


                                <div className="row mb-lg-8pt">

                                    <div className="col-lg-6">
                                        <div className="page-separator">
                                            <div className="page-separator__text">My Courses</div>
                                        </div>


                                        <div className="row" id="carousel-courses1" style={{borderWidth:1,borderColor:'black'}}>

                                            <div className="col-md-6">

                                                <div className="card card--elevated posts-card-popular overlay card-group-row__card  card-no-margin">
                                                    <img src="assets/images/paths/sketch_430x168.png" alt="" className="card-img"/>
                                                    <div className="fullbleed bg-primary" style={{opacity: 0}}></div>
                                                    <div className="posts-card-popular__content">
                                                        <div className="posts-card-popular__title card-body" style={{background:'#ffffff'}}>
                                                            {
                                                                dateDiff(new Date(),Courses[0]?.expire_date) < 0
                                                                ?
                                                                    <a onClick={()=>{
                                                                        alertify.alert('This Course Has Expired', 'Sorry, You cannot take this course at this time')

                                                                    }} className="card-title" style={{color:'#000'}} href="#">{Courses[0]?.Title}</a>
                                                                    :
                                                                    <a className="card-title" style={{color:'#000'}} href="./take-course">{Courses[0]?.Title}</a>

                                                            }

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>


                                            <div className="col-md-6">

                                                {
                                                    hasSurvey && myResult?.progress > lessonHelper.highestLockId(Lessons)
                                                        ?
                                                        <div className="card card--elevated posts-card-popular overlay card-group-row__card card-no-margin" style={{opacity:1}}>
                                                            <img src="assets/images/certified.png" alt="" className="card-img"/>
                                                            <div className="fullbleed bg-primary" style={{opacity: 0}}></div>
                                                            <div className="posts-card-popular__content">
                                                                <div className="posts-card-popular__title card-body" style={{background:'#ffffff'}}>
                                                                    <a className="card-title text-center text-success"
                                                                       download="download"
                                                                       target="_blank"
                                                                       href={`${API.Certificate}?key=${keya}&key2=${keyb}&./assets/certificate.pdf`
                                                                    } >Certificate is Ready <br/>Click Here to Download</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="card card--elevated posts-card-popular overlay card-group-row__card card-no-margin" style={{opacity:1}}>
                                                            <img src="assets/images/certified_pending.png" alt="" className="card-img"/>
                                                            <div className="fullbleed bg-primary" style={{opacity: 0}}></div>
                                                            <div className="posts-card-popular__content">
                                                                <div className="posts-card-popular__title card-body" style={{background:'#ffffff'}}>
                                                                    <a className="card-title text-center text-warning"  href="#">Certificate Pending</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }




                                            </div>


                                        {/*    <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="custom-select">All Courses</label>
                                                    <select id="custom-select" className="form-control custom-select">
                                                        <option>Select Course</option>
                                                        {
                                                            Courses?.map((oneCourse)=>{
                                                                return (<option selected value={oneCourse?.id}>{oneCourse?.Title}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
*/}
                                        </div>

                                    </div>
                                    <div className="col-lg-6">









                                        <div className="page-separator">
                                            <div className="page-separator__text">Course Status</div>
                                        </div>
                                        <div className="card card-no-margin">
                                            <div className="card-body noscroller" style={{padding:'10px',height:195}}>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div style={{
                                                            display:'flex',
                                                            flexDirection:'column',
                                                            alignContent:'center',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            height:'80%',
                                                        }}>
                                                            <div className="text-center blink_me" style={{color:'#89b935',fontSize:'smaller'}}>
                                                                Upcoming Meetings
                                                            </div>

                                                            <img
                                                                src={VideoBanner}
                                                                style={{height:40}}
                                                            />
                                                            <div className="text-center text-warning blink_me" style={{
                                                                fontStyle:'italic',
                                                                fontWeight: '700',
                                                                fontSize:'x-small'
                                                            }}>
                                                                {Meetings?.topic_id || "No Meeting Upcoming Meeting in your calendar"}
                                                                {!Meetings?.topic_id && <div>
                                                                    <a href="./meetings" className="btn-sm btn btn-success">
                                                                        Show Calendar
                                                                    </a>
                                                                </div>}
                                                            </div>
                                                                {Meetings?.meeting_date &&
                                                                    <div className="text-center"
                                                                        style={{fontSize:'x-small'}}
                                                                    >
                                                                        {
                                                                            moment(Meetings?.meeting_date).format('MMMM Do YYYY, h:mm:ss a')

                                                                        }
                                                                        <br/>
                                                                        <a href={Meetings?.meeting_link} target="_blank" className="btn-sm btn btn-success">
                                                                            Start
                                                                        </a>
                                                                        <a
                                                                            style={{marginTop:10}}
                                                                            href="./meetings" className="btn-sm btn btn-warning">
                                                                        Show Calendar
                                                                    </a>
                                                                    </div>
                                                                }



                                                        </div>







                                                    </div>
                                                    <div className="col-9">
                                                        <table className="statustable table table-bordered table-condensed table-striped table-dashboard">
                                                            <tr>
                                                                <th>Assigned Course</th>
                                                                <td>{Courses[0]?.Title}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Course Duration</th>
                                                                <td>{Math.abs(dateDiff(Courses[0]?.assign_date,Courses[0]?.expire_date))} Days</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Start Date</th>
                                                                <td>{Courses[0]?.assign_date && moment(Courses[0]?.assign_date).format("Do MMM, YYYY")}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>End Date</th>
                                                                <td>{Courses[0]?.expire_date && moment(Courses[0]?.expire_date).format("Do MMM, YYYY")}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Days Left</th>
                                                                <td>{Courses[0]?.expire_date && (dateDiff(new Date(),Courses[0]?.expire_date) < 0 ? 'Expired' : dateDiff(new Date(),Courses[0]?.expire_date)+" Days")} </td>
                                                            </tr>
                                                            {/* <tr>
                                                       <th>Certificate Status</th>
                                                       <td>
                                                           {

                                                               myResult?.progress > lessonHelper.highestLockId(Lessons) ? <a className="text-success" download href="./assets/certificate.pdf"><i className="fa fa-check"></i> Ready <br/>Click to Download</a> :  <span className="text-warning">Pending</span>
                                                           }

                                                       </td>
                                                   </tr>*/}
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">


                                    <div className="col-lg-6">
                                        <div className="page-separator">
                                            <div className="page-separator__text">Module completion status</div>
                                        </div>
                                        <div className="card">
                                            <div className="table-responsive" data-toggle="lists"
                                                 data-lists-sort-by="js-lists-values-employee-name"
                                                 data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>




                                                <table className="table mb-0 thead-border-top-0">

                                                    <thead style={{backgroundColor: '#89b935'}}>
                                                    <tr>
                                                        <th className="text-white">S.No</th>
                                                        <th className="text-white">Module Name</th>
                                                        <th className="text-white">Status</th>

                                                    </tr>
                                                    </thead>
                                                    <tbody className="list" id="search">
                                                    {
                                                        // Lessons.sort((a, b) => (a.id > b.id) ? 1: -1) &&
                                                        Lessons?.map((oneLesson,i)=>{
                                                            return (
                                                                <>
                                                                    <tr id={`Lesson${i}`} className="lessonRow">
                                                                        <td>
                                                                            {i+1}
                                                                        </td>
                                                                        <td>
                                                                            {oneLesson?.title}
                                                                        </td>
                                                                        <td>

                                                                            <button
                                                                                className={`btn btn-sm  ${getTiming(i)=='Completed' ? 'btn-success' : (getTiming(i)=='Pending' ? 'btn-warning' : 'btn-info')}`}
                                                                                onClick={()=>{
                                                                                    $(".Lessons").hide()
                                                                                    if(openTab != i){
                                                                                        $(".Lesson"+i).slideDown('slow')
                                                                                        setOpenTab(i)
                                                                                    }else{
                                                                                        setOpenTab(null)
                                                                                    }

                                                                                }}
                                                                            >
                                                                                {
                                                                                    getTiming(i)
                                                                                }
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        Lessons[i]?.lessons.sort((a, b) => (a.lockId > b.lockId) ? 1: -1) &&
                                                                        Lessons[i]?.lessons.map((oneLesson,j)=>{

                                                                            // console.log({OL:oneLesson})
                                                                            let OLStatus = topicTime(oneLesson?.lockId)


                                                                            return (
                                                                                <>
                                                                                    <tr
                                                                                        key={j}
                                                                                        style={{display:'none',backgroundColor: OLStatus ? '#b0df93' : 'whitesmoke'}}
                                                                                        // data-toggle={oneLesson?.activities ? "modal" : ""}
                                                                                        // data-target="#myModal"
                                                                                        className={`Lesson${i} Lessons`}
                                                                                        onClick={()=> {
                                                                                            setChildren(oneLesson.activities)
                                                                                            setVTitle(oneLesson?.Title)
                                                                                        }}

                                                                                    >
                                                                                        <td width={200} colSpan={2}>{oneLesson?.Title}</td>
                                                                                        <td style={{color: OLStatus ? 'blue' : 'black'}}>
                                                                                            {isOngoing(oneLesson?.activities) ? 'On-Going' : (OLStatus ? 'Completed' : 'Pending')}
                                                                                            <br/>{OLStatus ||  "0m 0s"}</td>
                                                                                    </tr>
                                                                                    {oneLesson?.activities?.map((oneAct,j)=>{
                                                                        let ACStatus = topicTime(oneAct?.lockId);

                                                                        return (
                                                                            <tr
                                                                                key={j}
                                                                                // style={{backgroundColor: ACStatus ? '#b0df93' : ''}}
                                                                                style={{display:'none',backgroundColor: ACStatus ? '#b0df93' : 'whitesmoke'}}
                                                                                className={`Lesson${i} Lessons`}

                                                                            >
                                                                                {/*<td>{oneAct?.lockId}</td>*/}
                                                                                <td width={200} colSpan={2}>{oneAct?.Title}</td>
                                                                                <td style={{color: ACStatus ? 'blue' : 'black'}}>
                                                                                    {ACStatus ? 'Completed' : 'Pending'} <br/>
                                                                                    {ACStatus || "0m 0s"}
                                                                                </td>
                                                                            </tr>

                                                                        )
                                                                    })}
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }


                                                    <tr className="lessonRow">
                                                        <td>
                                                            {Lessons?.length +1}
                                                        </td>
                                                        <td>
                                                            Quick Check for MOs
                                                        </td>
                                                        <td>
                                                            <button
                                                                className={`btn btn-sm  ${hasSurvey ? 'btn-success' : 'btn-warning'}`}
                                                                >
                                                                {hasSurvey ? 'Completed' : 'Pending'}

                                                            </button>


                                                        </td>
                                                    </tr>





                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <a style={{
                                            color:'#fff'
                                            }}
                                           href="ask-expert"
                                           onClick={()=>{
                                            // handleSave()
                                        }} type="button" className="btn btn-primary btn-block">ASK A MENTOR
                                        </a>
                                    </div>





                                    <div className="col-lg-6">
                                        <div className="page-separator">
                                            <div className="page-separator__text">Pre-Post score</div>
                                        </div>
                                        <div className="card">
                                            <div className="table-responsive" data-toggle="lists"
                                                 data-lists-sort-by="js-lists-values-employee-name"
                                                 data-lists-values='["js-lists-values-employee-name", "js-lists-values-employer-name", "js-lists-values-projects", "js-lists-values-activity", "js-lists-values-earnings"]'>
                                                <table className="table mb-0 thead-border-top-0">
                                                    <thead>
                                                    <tr style={{backgroundColor: '#89b935'}}>

                                                        <th className="text-white">S.No</th>
                                                        <th className="text-white">Module Name</th>
                                                        <th className="text-white" style={{minWidth:100}}>Pre-Test</th>
                                                        <th className="text-white" style={{minWidth:120}}>Post-Test</th>
                                                        {/*<th className="text-white">Attempts</th>*/}


                                                    </tr>
                                                    </thead>
                                                    <tbody className="list" id="search">
                                                    {
                                                        Lessons?.map((oneLesson,i)=>{
                                                            // console.log({OLe:oneLesson})
                                                            return (  <tr key={i}  className="lessonRow">
                                                                <td>
                                                                    <p className="mb-0"><strong
                                                                        className="js-lists-values-employee-name">{i+1}</strong></p>
                                                                </td>
                                                                <td>
                                                                    {oneLesson?.title}
                                                                </td>

                                                                <td>
                                                                    {
                                                                        ResultDetails?.find((oneDetail)=>{
                                                                            console.log({oneDetail,oneLesson})
                                                                            return oneDetail?.module_id == oneLesson?.id
                                                                        })?.pretest
                                                                    }
                                                                    {ResultDetails?.find((oneDetail)=>{
                                                                        return oneDetail?.module_id == oneLesson?.id
                                                                    })?.pretest && "/10"}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        ResultDetails?.find((oneDetail)=>{
                                                                            return oneDetail?.module_id == oneLesson?.id
                                                                        })?.posttest
                                                                    }
                                                                    {ResultDetails?.find((oneDetail)=>{
                                                                        return oneDetail?.module_id == oneLesson?.id
                                                                    })?.posttest && "/10"}
                                                                </td>
                                                               {/* <td>

                                                                </td>*/}


                                                            </tr>)
                                                        })
                                                    }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <a style={{
                                            color:'#fff',
                                            marginTop:24,
                                        }}
                                           href="rate-review"
                                           onClick={()=>{
                                               // handleSave()
                                           }} type="button" className="btn btn-primary btn-block">RATE AND REVIEW
                                        </a>
                                    </div>


                                </div>
                                <Faqs/>
                            </div>
                        </div>




                    </div>



                    <Footer/>

                </div>





                <script src="assets/vendor/jquery.min.js"></script>

                <script src="assets/vendor/popper.min.js"></script>
                <script src="assets/vendor/bootstrap.min.js"></script>

                <script src="assets/vendor/perfect-scrollbar.min.js"></script>

                <script src="assets/vendor/dom-factory.js"></script>

                <script src="assets/vendor/material-design-kit.js"></script>

                <script src="assets/vendor/fix-footer.js"></script>

                <script src="assets/js/app.js"></script>


                <script src="assets/js/settings.js"></script>

                <script src="assets/vendor/moment.min.js"></script>
                <script src="assets/vendor/moment-range.min.js"></script>

                <script src="assets/vendor/Chart.min.js"></script>

                <script src="assets/js/chartjs-rounded-bar.js"></script>
                <script src="assets/js/chartjs.js"></script>

                <script src="assets/js/page.instructor-dashboard.js"></script>

                <script src="assets/vendor/list.min.js"></script>
                <script src="assets/js/list.js"></script>

            </div>

            <div className="container">
                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{vTitle}</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                            </div>
                            <div className="modal-body">
                                <div className="col-lg-12 p-0 mx-auto">
                                    <div className="row">
                                        <div className="col-md-12 mb-24pt mb-md-0">
                                            <table className="table table-condensed table-striped">
                                                {
                                                    vChildren?.map((oneAct,j)=>{
                                                    let ACStatus = topicTime(oneAct?.lockId);

                                                    return (
                                                    <tr key={j} style={{backgroundColor: ACStatus ? '#b0df93' : ''}}>
                                                    <td>{j+1}</td>
                                                    <td width={200}>{oneAct?.Title}</td>
                                                    <td style={{color: ACStatus ? 'blue' : 'black'}}>{ACStatus ? 'Completed' : 'Pending'}</td>
                                                    <td>{ACStatus || "0m 0s"}</td>
                                                    </tr>

                                                    )
                                                })
                                                }
                                            </table>


                                            {/*{oneLesson?.activities?.map((oneAct,j)=>{
                                                                        let ACStatus = topicTime(oneAct?.lockId);

                                                                        return (
                                                                            <tr key={j} style={{backgroundColor: ACStatus ? '#b0df93' : ''}}>
                                                                                <td>{oneAct?.lockId}</td>
                                                                                <td width={200}>{oneAct?.Title}</td>
                                                                                <td style={{color: ACStatus ? 'blue' : 'black'}}>{ACStatus ? 'Completed' : 'Pending'}</td>
                                                                                <td>{ACStatus || "0m 0s"}</td>
                                                                            </tr>

                                                                        )
                                                                    })}*/}
                                        </div>

                                    </div>
                                </div>






                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


        </div>

    );
};

export default Dashboard;