import React, {useState} from 'react';

const Header = (Prop) => {
    // console.log({Prop})
    const [Show,setShow] = useState(false)
    return (
        <div id="header" className="mdk-header js-mdk-header mb-0" data-fixed data-effects="">
            <div className="mdk-header__content">

                <div className="navbar navbar-expand pr-0 navbar-dark-dodger-blue navbar-shadow row" id="default-navbar"
                     data-primary>
                   
                    <div className="col-xs-4 col-md-4">
                        <a href="#" className="navbar-brand mr-16pt ">
                        <img src="assets/images/logo/rksk_logo.png" style={{height:80}} alt="logo" className="Ximg-fluid"/>
                    </a>
                    </div>
                    <div className="col-xs-4 col-md-4">
                        <a href="#" className="navbar-brand mr-16pt" style={{display:'flex',justifyContent:'center'}}>
                        <img src="assets/images/logo/nhm.png" style={{height:63}} alt="logo" className="Ximg-fluid"/>
                        </a>
                    </div>
                    <div className="col-xs-4 col-md-4 text-right">
                        <div className="row text-right" style={{float:'right'}}>
                            <img src="assets/images/logo/mamta_1.png" style={{height:63}} alt="logo" className="Ximg-fluid"/>
                            <div classNames="nav navbar-nav flex-nowrap d-flex mr-16pt">
                                <div className="nav-item dropdown">
                                    {/* <a href="#" className="">
                                    <img src="assets/images/logo/goi.png" style={{width:'75%'}} className="img-fluid" alt="logo"/>
                                </a> */}
                                    {/*<a href="#" className="">
                                    <img src="assets/images/logo/mamta.png" style={{width:'73%'}} className="img-fluid"
                                         alt="logo"/>
                                </a>*/}
                                    {/* <a href="#" className="">
                                    <img src="assets/images/logo/nhm.png" style={{width:'73%'}} className="img-fluid"
                                         alt="logo"/>
                                </a> */}
                                    <a href="#" className="nav-link d-flex align-items-center dropdown-toggle"
                                       data-toggle="dropdown" data-caret="false">

                        <span className="avatar avatar-sm mr-8pt2">

                            {Prop?.expert ? <span className="avatar-title"><a onClick={()=>{
                                localStorage.clear();
                                window.location.href="./"
                            }
                            }>Logout</a></span> : <span className="avatar-title">Login</span> }


                        </span>

                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="./login">User Login</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default Header;