import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="js-fix-footer bg-white border-top-2" data-domfactory-upgraded="fix-footer"
                 style={{opacity: 1}}>
                <div className="container Xpage-section Xpy-lg-48pt" style={{padding:5}}>
                    <div className="row" style={{height:135,paddingTop:40}}>
                        <div className="col-md-4 mb-24pt mb-md-0">
                        {/* <img className="brand-icon" src="assets/images/logo/mamta_1.png"  alt="Tutorio" style={{height:53,width:214}}/> */}
                        </div>
                        <div className="col-md-4 mb-32pt mb-md-0" style={{textAlign:'center'}}>
                        <img className="brand-icon" src="assets/images/logo/pathfinder.png"  alt="Tutorio" style={{height:27,width:184}}/>
                        </div>
                        <div className="col-md-4 mb-24pt mb-md-0 text-right">
                            {/* <img className="brand-icon" src="assets/images/logo/pathfinder.png"  alt="Tutorio" style={{height:40,width:184}}/> */}
                        </div>
                        <div>
                            <span style={{paddingLeft: "78"}}>The e-learning platform has been developed by National Health Mission, Himachal Pradesh in partnership with Mamta Health Institute for Mother and Child and Pathfinder International</span>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-12 col-md-12 mb-24pt mb-md-0">
                                    <nav className="nav nav-links nav--flush flex-column">
                                    <p className="text-black mb-0" style={{textAlign: "center", fontWeight: "500"}}>Credit: The e-learning platform is developed with support from WHO AYSRHR Technical Assistance Coordination Mechanism</p>

                                    </nav>
                                </div>
                                <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                    {/*<h4 className="text-70">Courses</h4>
                                    <nav className="nav nav-links nav--flush flex-column">
                                        <a className="nav-link" href="#">Program 1</a>
                                        <a className="nav-link" href="#">Program 2</a>
                                        <a className="nav-link" href="#">Program 3</a>
                                        <a className="nav-link" href="#">Program 4</a>
                                    </nav>*/}
                                </div>
                                <div className="col-6 col-md-4 mb-32pt mb-md-0">
                                    {/*<h4 className="text-70">Community</h4>
                                    <nav className="nav nav-links nav--flush flex-column">
                                        <a className="nav-link" href="#">Discussions</a>
                                        <a className="nav-link" href="#">Ask Question</a>
                                        <a className="nav-link" href="#">Student Profile</a>
                                        <a className="nav-link" href="#">Instructor Profile</a>
                                    </nav>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-md-right">
                            {/* <p className="text-muted mb-0 mb-lg-16pt">eLearning Platform for Medical Officers, ANMs,
                                Peer Educators cover health related education programs with certifications.</p> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Footer;