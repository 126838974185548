import React, {useState} from 'react';

const Header = (Prop) => {
    // console.log({Prop})
    const [Show,setShow] = useState(false)
    return (
        <div id="header" className="mdk-header js-mdk-header mb-0" data-fixed data-effects="">
            <div className="mdk-header__content">

                <div className="navbar navbar-expand pr-0 navbar-dark-dodger-blue navbar-shadow row" id="default-navbar"
                     data-primary>
                    <div className="col-xs-4 col-md-4">
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <button onClick={()=>Prop.showMenu(!Show)} className="navbar-toggler w-auto mr-16pt d-block rounded-0" type="button"
                                    data-toggle="sidebar">
                                <span className="material-icons">short_text</span>
                            </button>

                            <a href="#" className="navbar-brand mr-16pt ">
                             <img src="assets/images/logo/rksk_logo.png" style={{height:80}} alt="logo" className="Ximg-fluid"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-4 col-md-4 text-center">
                        <a href="#" className="navbar-brand mr-16pt" style={{display:'flex',justifyContent:'center'}}>
                        <img src="assets/images/logo/nhm.png" style={{height:80}} alt="logo" className="Ximg-fluid"/>
                        </a>
                    </div>
                    <div className="col-xs-4 col-md-4" >
                        <div style={{display:'flex',flexDirection:'row-reverse'}}>
                            <div className="nav navbar-nav flex-nowrap d-flex mr-16pt" style={{width:100}}>
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link d-flex align-items-center dropdown-toggle"
                                       data-toggle="dropdown" data-caret="false">
                                        <span className="avatar avatar-sm mr-8pt2">
                                            <span className="avatar-title" style={{justifyContent:'flex-start'}}>{JSON.parse(localStorage.userData)?.full_name}</span>
                                        </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <div className="dropdown-header"><strong>User</strong></div>
                                        <a className="dropdown-item" href="./edit-profile">Edit Profile</a>
                                        {/* <a className="dropdown-item" href="./ask-expert">Ask a Mentor</a> */}
                                        {/*<a className="dropdown-item" href="student-change-password.php">Change Password</a>*/}
                                        <a className="dropdown-item" href=".#" onClick={()=>{
                                            localStorage.clear();
                                            window.location.href="./login"
                                        }}>Logout</a>
                                    </div>
                                </div>
                            </div>
                            <img src="assets/images/logo/mamta_1.png" style={{height:63}} alt="logo" className="Ximg-fluid"/>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Header;