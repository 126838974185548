import React, {useEffect, useState} from 'react';
import Overlay from "../Components/Overlay";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import $ from "jquery";
import alertify from "alertifyjs";
import API from "../Queries/API";
import {Checker} from "../Queries/Sessions";
import moment from "moment/moment";
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import tippy from 'tippy.js'; // Import Tippy.js
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import EventComponent from "../Components/EventComponent"; // Optional animations



const Meetings = () => {
    const [showMenu, setShowMenu] = useState(false)
    const [Events,setEvents] = useState([])

    const eventRender = ({ event }) => {
        return (
            <a target="_blank" href={event?.url}>
                <strong>{event.title}</strong>
                <div style={{display: event?.title == 'Join Now' ? 'none' : 'block'}}>
                    <br />
                    {moment(event.start).format('YYYY-MM-DD H:M:s a')}
                    <br/>
                    {event?.url}
                </div>

            </a>
        );
    };

   /* useEffect(() => {
        // Fetch your JSON data here and update the 'events' state.
        // You can use the Fetch API or any other library for this purpose.
        fetch('https://fullcalendar.io/api/demo-feeds/events.json?start=2023-10-01T00%3A00%3A00Z&end=2023-11-12T00%3A00%3A00Z&timeZone=UTC')
            .then((response) => response.json())
            .then((data) => {
                 setEvents(data); // Update the 'events' state with the JSON data
            })
            .catch((error) => {
                console.error('Error fetching JSON data:', error);
            });
    }, []); // Empty dependency array to run the effect only once
*/


    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.usertoken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(API.meeting, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({result})
                //create details
                let m = result?.meetingData?.map((oneMeet,I)=>{
                    return {
                        title: oneMeet?.topic_id,
                        description:oneMeet?.topic_id,
                        start: oneMeet?.meeting_date,
                        groupId: oneMeet?.schedule_id,
                        url: oneMeet?.meeting_link,
                    }
                })

                //create only titles
                let g = result?.meetingData?.map((oneMeet,I)=>{
                    return {
                        title: 'Join Now',//oneMeet?.topic_id,
                        description:oneMeet?.meeting_link,
                        start: moment(oneMeet?.meeting_date).format('YYYY-MM-DD'),
                        groupId: oneMeet?.schedule_id,
                        url: oneMeet?.meeting_link,

                    }
                })




                console.log({m})
                setEvents([...m,...g])
                // setEvents(m)
                // if(result?.meetingData?.length){
                //     setMeetings(result?.meetingData?.[0])
                // }
            })
            .catch(error => console.log('error', error));
    },[])





    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default">
                <div className="preloader">
                    <div className="sk-double-bounce">
                        <div className="sk-child sk-double-bounce1"></div>
                        <div className="sk-child sk-double-bounce2"></div>
                    </div>
                </div>

                <div className="mdk-header-layout js-mdk-header-layout" style={{backgroundColor: '#ffffff'}}>

                    <Header showMenu={setShowMenu}/>



                    <div className="mdk-header-layout__content page-content ">


                        <div className="container page__container">


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="page-section">


                                        <FullCalendar

                                            plugins={[dayGridPlugin, interactionPlugin]}
                                            initialView="dayGridMonth" // Set your initial view
                                            events={Events} // Pass your JSON data as events
                                            eventContent={eventRender}

                                           /* eventContent={(arg) => (
                                                <EventComponent event={arg.event} />
                                            )}*/

                                        />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <Footer/>

                    </div>


                </div>
            </div>



        </div>
    );
};

export default Meetings;