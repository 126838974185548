import React, {useEffect, useState} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import Overlay from "../Components/Overlay";
import {Checker} from "../Queries/Sessions"
import API from "../Queries/API";
import Lessons from "../DataFiles/Lessons";

const TestResult = (Prop) => {
    const [showMenu, setShowMenu] = useState(false)
    const [myResult, setMR] = useState({})
    const [questAns, setQA] = useState([])
    const [resultMeta, setRM] = useState({})
    useEffect(()=>{
        if(myResult?.pretestboard || myResult?.posttestboard){
            console.log({myResult})
            let mr = JSON?.parse(Prop?.mode === "preTest" ? myResult?.pretestboard : myResult ?.posttestboard);
            console.log({mr})
            setRM(mr)

            let arr = [];

            for (const key in mr) {
                if(isNaN(mr[key])){
                    arr.push(mr[key])
                }
                // console.log(`${property}: ${object[property]}`);
            }
            console.log({arr})
            setQA(arr)
        }

    },[myResult])
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'getscores',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({serverProgress:result})

                //determine module id
                let moduleId = 0;
                const url = new URL(window.location.href);
                const queryParams = new URLSearchParams(url.search);
                // debugger;
                if (queryParams.has('module') && result?.detail?.length >= queryParams.get('module')) {
                    moduleId = parseInt(queryParams.get('module')) - 1
                }

                //end of determine module id



                setMR(result?.detail?.[moduleId])
            })
            .catch(error => console.log('error', error));


    },[])







    useEffect(()=>{
        // console.log("ss")
        Checker()
        console.log({Prop})
    },[])

    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default ">

            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <div className="mdk-header-layout js-mdk-header-layout">

                <Header showMenu={setShowMenu}/>
                <div className="mdk-header-layout__content page-content ">


                    <div className="navbar navbar-list navbar-light border-bottom navbar-expand-sm" style={{whiteSpace: 'nowrap'}}>
                        <div className="container page__container">
                            <nav className="nav navbar-nav">
                                <div className="nav-item navbar-list__item">
                                    <a href="./quiz-score" className="nav-link h-auto"><i className="material-icons icon--left">keyboard_backspace</i> Back to Scores</a>
                                </div>
                                <div className="nav-item navbar-list__item">
                                    <div className="d-flex align-items-center flex-nowrap">
                                        {/*<div className="mr-16pt">
                                            <a href="#"><img src="assets/images/paths/angular_64x64.png" width="40" alt="Angular" className="rounded"/></a>
                                        </div>*/}
                                        <div className="flex">
                                            <a href="#" className="card-title text-body mb-0">{Lessons[0]?.title} {Prop?.mode.toUpperCase()} Result</a>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>


                    <div className="mdk-box bg-primary mdk-box--bg-gradient-primary2 js-mdk-box mb-0" data-effects="blend-background">
                        <div className="mdk-box__content" style={{paddingTop:20}}>
                            <div className="row">

                                <div className="col-lg-3 card-group-row__col ">
                                    <div className="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card" data-toggle="popover" data-trigger="click">
                                        <div className="card-body ">
                                            <div className="">
                                                <div className="">
                                                    <h2 className="card-title text-center font-sz1">Total Attempt Question</h2>
                                                    <p className="text-center text-60 font-weight-bold mb-4pt font-sz">{resultMeta?.testCount}/{resultMeta?.testCount}</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-footer"> </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 card-group-row__col">

                                    <div className="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card" data-toggle="popover" data-trigger="click">
                                        <div className="card-body flex">
                                            <div className="d-flex">
                                                <div className="flex">
                                                    <h2 className="card-title text-center font-sz1">Total Corrected  Question</h2>
                                                    <p className="text-center text-60 font-weight-bold mb-4pt font-sz">{questAns?.filter((I)=>I?.A === 'pass')?.length}/{resultMeta?.testCount}</p>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-footer"></div>
                                    </div>
                                </div>

                                <div className="col-lg-3 card-group-row__col">

                                    <div className="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card" data-toggle="popover" data-trigger="click">
                                        <div className="card-body flex">
                                            <div className="d-flex">
                                                <div className="flex">
                                                    <h2 className="card-title text-center font-sz1">Total Incorrected  Question</h2>

                                                    <p className="text-center text-60 font-weight-bold mb-4pt font-sz">{questAns?.filter((I)=>I?.A === 'fail')?.length}/{resultMeta?.testCount}</p>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-footer"></div>
                                    </div>
                                </div>

                                <div className="col-lg-3 card-group-row__col">

                                    <div className="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card" data-toggle="popover" data-trigger="click">
                                        <div className="card-body flex">
                                            <div className="d-flex">
                                                <div className="flex">
                                                    <h1 className="card-title text-center font-sz1">Total Percetage</h1>
                                                    <p className="text-center text-60 font-weight-bold mb-4pt font-sz">{(questAns?.filter((I) => I?.A === 'pass')?.length / resultMeta?.testCount)*100}%</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="card-footer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="navbar navbar-expand-sm navbar-light navbar-submenu navbar-list p-0 m-0 align-items-center">
                        <div className="container page__container">
                            <ul className="nav navbar-nav flex align-items-sm-center">
                                <li className="nav-item navbar-list__item">
                                    <i className="material-icons text-muted icon--left">schedule</i>
                                    0 minutes
                                </li>
                                <li className="nav-item navbar-list__item">
                                    <i className="material-icons text-muted icon--left">assessment</i>
                                    Intermediate
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="container page__container page-section">


                        <div className="page-separator">
                            <div className="page-separator__text">Module 1  Post Test Result</div>
                        </div>

                        <div className="card mb-lg-32pt">

                            <div className="table-responsive" data-toggle="lists" data-lists-values='["js-lists-values-name"]'>
                                <table className="table table-bordered table-flush mb-0 thead-border-top-0 table-nowrap">
                                    <thead>
                                    <tr>
                                    <th>S.No</th>
                                    <th> Questions  </th>
                                    <th> Wrong Answer</th>
                                    <th> Right Answer</th>
                                    <th style={{width: 24}}></th>
                                    </tr>
                                </thead>
                                <tbody className="list" id="contacts">

                                {
                                    questAns.map((oneAns,Index)=>{
                                       return(
                                           <tr key={Index}>

                                               <td className="pr-0 border-right-0">
                                                   {Index+1}
                                               </td>


                                               <td>


                                                   <a className="d-flex flex-column border-1 rounded bg-light px-8pt py-4pt lh-1" href="#">
                                                       <small><strong className="js-lists-values-name text-black-100 text-wrap">{oneAns?.Q}</strong></small>
                                                   </a>


                                               </td>
                                               <td>
                                                   {
                                                       oneAns?.A === "fail" ? <span>&#10060;</span> : ""
                                                   }

                                               </td>
                                               <td>
                                                   {
                                                       oneAns?.A === "pass" ?
                                                           <i className="fa fa-check" aria-hidden="true"
                                                              style={{color: 'green'}}></i> : ""
                                                   }
                                               </td>

                                           </tr>
                                       )
                                    })
                                }


                               {/* <tr>

                                    <td className="pr-0 border-right-0">
                                        10
                                    </td>


                                    <td>


                                        <a className="d-flex flex-column border-1 rounded bg-light px-8pt py-4pt lh-1" href="">
                                            <small><strong className="js-lists-values-name text-black-100">General question regarding documentation and salary expectations.</strong></small>
                                        </a>


                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        <span style={{fontSize: 19}}><i className="fa fa-check" aria-hidden="true" style={{color: 'green'}}></i></span>
                                    </td>
                                </tr>*/}


                                </tbody>
                            </table>
                        </div>


                    </div>
                </div>




            </div>

            <Footer/>

                </div>

            </div>

        </div>

    );
};

const cardGroupRowCol = {
    marginTop:24
}
const fontSz = {
    fontSize:19,
    color:'#ff0000'
}
const fontSz1 ={
    fontSize: 17
}

export default TestResult;