import React from 'react';
import {Image} from "@mui/icons-material";
const PreviewImg = require("../Assets/Images/PreviewImg.png")
const PreviewScreen = (Prop) => {
    console.log({previewProp:Prop})
    let mainTitle = Prop?.Lessons?.find((oneLesson)=>{
        return oneLesson?.id == parseInt(Prop?.curModule)+1
    })
    return (
        <div>
            <img src={PreviewImg} style={{width:'100%'}}/>
            <h2 style={{textAlign:'center'}}>Session {Prop?.curModule +1}: {mainTitle?.title}</h2>
            {/*<h2 style={{textAlign:'center'}}>Session {Prop?.curModule +1}: {Prop?.curTitle}</h2>*/}
        </div>
    );
};

export default PreviewScreen;