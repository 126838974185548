import React from 'react';
import {lessonHelper} from "../Helpers/lessonHelpers";
import jAlert from "../Helpers/jAlert";

const CheckInstructions = (Prop) => {
    return (
        <div>
            <div style={{background:'#fff',height:300,padding:20,textAlign:'center',fontSize:25,fontStyle:'italic',color:'#FF0000'}}><b>Instruction: </b>Tick the option that you find most appropriate for the following statements – (1) being the least appropriate and (5) being the most appropriate

                <br/>
                <br/>
                <button className="btn btn-danger" onClick={()=>{
                    console.log({mySurveyScores:Prop?.mySurveyScores})
                    // return;




                    Prop?.setCurTitle("Quick Check for MOs")
                    Prop?.setCurPlayer('Survey')
                    // Prop?.setCurModule(oneLesson?.id)
                    // Prop.setCurLesson(oneAct)
                }}>Start </button>

            </div>

        </div>
    );
};

export default CheckInstructions;