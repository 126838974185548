import React, {useEffect, useRef, useState} from 'react';
import jAlert from "../Helpers/jAlert";
import alertify from 'alertifyjs'


import $ from 'jquery';
import {lessonHelper} from "../Helpers/lessonHelpers";
import useLessons from "../DataFiles/Lessons";
import API from "../Queries/API";
import {encode as base64_encode} from "base-64";


const PlayerList = (Prop) => {

    const {data:Lessons, isLoading, error} = useLessons()
    // const Lessons = []

    const [openTab, setOpenTab] = useState(null)
    useEffect(()=>{
        Prop?.setOpenMenu(openTab)
    },[openTab])
    const [keya, setKeya] = useState("RXJyb3I=");
    const [keyb, setKeyb] = useState("RXJyb3I=");

    const [playQuiz, showPlayQuiz] = useState(false);
    // console.log(Lessons)
    // console.log(Prop?.curPlayer)
    // console.log({LiProp:Prop})
    // console.log({PLProp:Prop})
    // const [curLockId, setCLI] = useState(0)

    const getColor=(x)=>{
        const T =  lessonHelper.mapLockId(Lessons)[x]

        if(Prop?.curLockId > T.maxLock){
            return "list-group-item-success";
        }

        if(Prop?.curLockId >= T.minLock && Prop?.curLockId <= T.maxLock){
            return "list-group-item-warning";
        }
        // debugger;
        if(Prop?.curLockId < T.maxLock){
            return "list-group-item-danger";
        }

        // return "list-group-item-warning";
    }
    useEffect(()=>{
        let uInfo = JSON?.parse(localStorage?.userData)
        // console.log({uInfo})
        setKeya(base64_encode(uInfo?.full_name))


        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "stateId": uInfo?.state_id,
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(API.getDistrict, requestOptions)
            .then(response => response.json())
            .then(result => {
                let dd = result?.district?.find((oneD)=>{
                    return oneD?.district_id == uInfo?.district_id
                })
                dd && setKeyb(base64_encode(dd?.district_name));
            })
            .catch(error => console.log('error', error))
        // .finally(next && next());

    },[])

    const openByModule = (moduleId)=>{
        // debugger;
        console.log({Lessons})
        if(moduleId >= Lessons.length){
            Prop?.setCurPlayer("CheckInstructions")
            return;
        }else{
            //open the tab
            let P = moduleId;
            // $(".module-submenu").slideUp();
            if(Lessons[P]?.expired==='true'){
                jAlert("This course is expired")
                return;
            }
            $("#module"+P+"-submenu").slideDown('slow')
            setOpenTab(P)
            console.log({moduleId:P})
            Prop?.setCurModule(P+1)
            console.log({curModuleLoaded:(P+1)})
            Prop?.setCurQuestions(Lessons[P]?.pretest)
            console.log({curQuestionLoaded:Lessons[P]?.pretest})
            let Les = Lessons[P]?.lessons;




            Les?.sort((a, b) => (a.id > b.id) ? 1: -1)
            let oneMenu = Les?.[0];
            // debugger;
            // console.log({oneMenu})

            if(oneMenu?.lockId > Prop?.curLockId && (oneMenu?.lockId > 1 || !Prop?.pretested)){
                // jAlert('Lesson is Locked <br/>Unlock this lesson by completing the previous task')

                console.log({
                    oneMenu,
                    Prop
                })


                // Prop?.setCurSubTitle("General Guidelines")
                Prop?.setCurPlayer("PreviewScreen")
                Prop?.setCurModule(moduleId)


            }else{
                Prop?.setCurSubTitle(oneMenu?.Title)
                Prop?.setCurPlayer(oneMenu?.Type)
                // Prop?.setCurModule(P)
                Prop.setCurLesson(oneMenu)
            }

        }




    }
    useEffect(()=>{
        if(Lessons?.length > 0){
            const url = new URL(window.location.href);
            const queryParams = new URLSearchParams(url.search);
            if (queryParams.has('module')) {
                const moduleValue = queryParams.get('module');
                // console.log({Lessons})
                Prop?.setUGM(moduleValue) //update state in TakeCourse
                setTimeout(()=>{
                    openByModule(parseInt(moduleValue)-1)
                },1000)
            }

        }



    },[Lessons,Prop?.pretested])

    //DYNAMIC CLICK
    const linkRefs = useRef([]);

    useEffect(() => {
        // When activeButton changes, trigger a click on the corresponding button
        if (Prop?.curLockId !== null && linkRefs.current[Prop?.curLockId]) {
            linkRefs.current[Prop?.curLockId].click();
        }
        // console.log({linkRefs})
        // console.log({switchedlockid:Prop?.curLockId})
    }, [Prop?.curLockId]);

    //END DYNAMIC CLICK




    return (
        <div className="flex ps" style={{backgroundColor:'#cde9a5',height:'100%'}}>

            <div className="sidebar-heading">Training on AFHS for Medical Officers</div>

            <div className="list-group list-group-flush mb-3">

                <nav>
                    <ul style={{cursor:'pointer'}} className="list-group-item px-3 py-12pt bg-light">
                        <a id="" className="d-flex align-items-center position-relative" onClick={()=>{
                            Prop?.setCurSubTitle("")
                            Prop?.setCurTitle("General Instructions")
                            Prop?.setCurPlayer("Instruction")
                            Prop?.setCurModule("SM")
                        }}>
                            <i className="fa fa-circle p-1"></i>
                            <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                <strong className="text-body">General Guidelines</strong>
                            </span>
                        </a>
                    </ul>


                    {
                        Lessons.map((oneLesson,P)=>{

                            // console.log({oneLesson})
                            if(oneLesson?.expired==='true'){

                                alertify.alert('This Course Has Expired', 'Sorry, You cannot take this course at this time').set('onok', function(closeEvent){
                                       window.history.go(-1);
                                    }
                                );


                            }
                            // let mxl = lessonHelper.mapLockId(Lessons)[P].maxLock;
                            // console.log({mxl})
                            return <ul style={{cursor:'pointer'}} className={`list-group-item px-3 py-12pt ${getColor(P)}`} key={P}>
                                <a id={`module${P}`} className="d-flex align-items-center position-relative"  onClick={()=>{

                                   //handles - cannot change module if in test screen
                                    console.log({analysis:{openTab,P,curPlayer:Prop?.curPlayer}})
                                    if((Prop?.curPlayer == 'preTest' || Prop?.curPlayer == 'postTest') && P != openTab){
                                        console.log("Cannot Switch Test in process")
                                        return;
                                    }



                                    if(oneLesson?.expired==='true'){
                                        jAlert("This course is expired")
                                        return;
                                    }
                                    console.log({P})
                                    console.log({minlock:lessonHelper.mapLockId(Lessons)[P]?.minLock})

                                    if(Prop?.curLockId >= (lessonHelper.mapLockId(Lessons)[P]?.minLock-0.5) || P==0){
                                        $(".module-submenu").slideUp();

                                        if(openTab != P){
                                            $("#module"+P+"-submenu").slideDown('slow')
                                            setOpenTab(P)
                                            Prop?.setCurModule(P+1)
                                        }else{
                                            setOpenTab(null)
                                        }
                                    }
// debugger;
                                }}>
                                    {/*<i className="fa fa-caret-right p-1"></i>*/}
                                    <i className={Prop?.curLockId-0.5 >= (lessonHelper.mapLockId(Lessons)[P]?.minLock-1) || P==0 ? "fa fa-caret-right" : "fa fa-lock p-1"}></i>
                                    <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                <strong className="text-body">{oneLesson?.title}</strong>
                                        {/*{oneLesson?.expired==='true' && <span class="text-danger" style={{fontStyle:'italic'}}>Expired</span>}*/}
                            </span>
                                </a>
                                <ul id={`module${P}-submenu`} className="module-submenu" style={{display:'none',paddingLeft:23}}>
                                    <li style={{paddingBottom:6,marginTop:8}}>
                                        <a href="#" id={`module${P+1}`} onClick={()=> {
                                            //confirm from user
                                            if(!window.confirm('Are You Sure you want to proceed with the test? \nYou cannot leave the test screen when you start')){
                                                return;
                                            }
                                            Prop?.setCurModule(P+1)
                                            $(".module-submenu").slideUp();
                                            //end of user permission





                                            console.log({Prop})
                                            if(((parseInt(Prop?.curLockId) != (lessonHelper.mapLockId(Lessons)[P]?.minLock-1)) || Prop?.curLockId==0) && (Prop?.curPlayer==='TestResult' || Prop?.pretested)){
                                                return;
                                            }
                                            Prop?.setCurSubTitle('Pre Test')
                                            Prop?.setCurPlayer('preTest')
                                        }}
                                           className="d-flex align-items-center position-relative">
                                            <i className="fa fa-file" style={{fontSize:12,marginRight: 7}}></i>
                                            <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                        <strong className={((parseInt(Prop?.curLockId) != (lessonHelper.mapLockId(Lessons)[P]?.minLock-1)) || Prop?.curLockId==0) && (Prop?.curPlayer==='TestResult' || Prop?.pretested) ? "locked-link text-body" : "text-body"}>Pre-Test</strong>
                                    </span>
                                        </a>
                                    </li>
                                    {

                                        oneLesson.lessons.sort((a, b) => (a.id > b.id) ? 1: -1) && oneLesson?.lessons.map((oneMenu)=>{

                                            return (
                                                <div key={oneMenu?.id}>
                                                    <li
                                                        key={oneMenu?.id}
                                                        style={{paddingBottom:6,marginTop:8}}>
                                                        <a
                                                           href="#"
                                                           ref={el => linkRefs.current[oneMenu?.lockId] = el}
                                                           id={`module${P+1}`}
                                                           className="d-flex align-items-center position-relative"
                                                           onClick={()=> {
                                                            console.log({oneMenu})
                                                            if(oneMenu?.lockId > Prop?.curLockId && (oneMenu?.lockId > 1 || !Prop?.pretested)){
                                                                jAlert('Lesson is Locked <br/>Unlock this lesson by completing the previous task')
                                                                return;
                                                            }
                                                            Prop?.setCurSubTitle(oneMenu?.Title)
                                                            Prop?.setCurPlayer(oneMenu?.Type)
                                                            Prop?.setCurModule(oneLesson?.id)

                                                            Prop.setCurLesson(oneMenu)
                                                            // console.log({Prop})
                                                        }}>

                                                            <i className={oneMenu?.Type == 'Vimeo' ? "fa fa-play-circle" : "fa fa-file-pdf-o"} style={{fontSize:12,marginRight: 7}}></i>
                                                            <span className="flex d-flex flex-column" style={{maxWidth:240}}>
                                                           <span className={oneMenu?.lockId > Prop?.curLockId && (oneMenu?.lockId > 1 || !Prop?.pretested) ? 'locked-link text-body' : 'unlocked-link text-body'} style={{width:'80%', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{oneMenu?.Title}</span>
                                                       </span>
                                                        </a>
                                                    </li>
                                                    {
                                                        oneMenu?.activities?.length > 0 && oneMenu?.activities.sort((a, b) => (a.id > b.id) ? 1: -1) && oneMenu?.activities?.map((oneAct)=>{
                                                        return (
                                                            <li key={oneAct?.id} style={{paddingBottom: 6, marginTop: 8,paddingLeft:20}}>
                                                                <a
                                                                   href="#"
                                                                   ref={el => linkRefs.current[oneAct?.lockId] = el}
                                                                   id={`module${P+1}-${oneAct?.id}`}
                                                                   className="d-flex align-items-center position-relative"
                                                                   onClick={() => {
                                                                    console.log({oneAct})
                                                                    if(oneAct?.lockId > Prop?.curLockId){
                                                                        jAlert('Activity is Locked<br/>Unlock this Activity by completing the previous task')
                                                                        return;
                                                                    }
                                                                    Prop?.setCurSubTitle(oneAct?.Title)
                                                                    Prop?.setCurPlayer('Activity')
                                                                    Prop?.setCurModule(oneLesson?.id)
                                                                    Prop.setCurLesson(oneAct)
                                                                    // console.log({Prop})
                                                                }}>

                                                                    <i className="fa fa-trophy"
                                                                       style={{fontSize: 12, marginRight: 7}}></i>
                                                                    <span className="flex d-flex flex-column" style={{maxWidth: 225}}>
                                                           <span
                                                               className={oneAct?.lockId > Prop?.curLockId ? 'locked-link text-body' : 'unlocked-link text-body'}
                                                               style={{
                                                                   width: '80%',
                                                                   whiteSpace: 'nowrap',
                                                                   overflow: 'hidden',
                                                                   textOverflow: 'ellipsis'
                                                               }}>{oneAct?.Title}</span>
                                                       </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })
                                    }

                                    <li style={{paddingBottom:6,marginTop:8}}>
                                        <a href="#" id={`module${P+1}`} onClick={()=> {
                                            if(Prop?.istested == 0){
                                                jAlert('Complete All The Lessons First')
                                                return;
                                            }

                                            //confirm from user
                                            if(!window.confirm('Are You Sure you want to proceed with the test? \nYou cannot leave the test screen when you start')){
                                                return;
                                            }
                                            $(".module-submenu").slideUp();
                                            //end of user permission



                                            console.log({Prop})
                                            if(lessonHelper.mapLockId(Lessons)[P]?.maxLock != Prop?.curLockId){
                                                jAlert('Post Test is Locked<br/>Unlock this Test by completing all the Lessons and activities')
                                                return;
                                            }
                                            Prop?.setCurSubTitle('Post Test')
                                            Prop?.setCurPlayer('postTest')
                                        }}
                                           className="d-flex align-items-center position-relative">
                                            <i className="fa fa-file" style={{fontSize:12,marginRight: 7}}></i>
                                            <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                        <strong
                                            className={((lessonHelper.mapLockId(Lessons)[P]?.maxLock != Prop?.curLockId) || (Prop?.istested ==0)) ? 'locked-link text-body' : 'unlocked-link text-body'}
                                        >Post-Test</strong>
                                    </span>
                                        </a>
                                    </li>
                                    {
                                        oneLesson?.resource &&
                                                <li style={{paddingBottom:6,marginTop:8}}>
                                                    <a href="#" id="module1" onClick={()=> {
                                                        $(".module-submenu").slideUp();

                                                        // Prop?.setCurSubTitle('Post Test')
                                                        // Prop?.setCurPlayer('postTest')

                                                        // console.log({oneLesson})
                                                        // console.log({xxProp:Prop})
                                                        // return;
                                                        Prop?.setCurSubTitle("Resources")
                                                        Prop?.setCurPlayer("FlipBook")
                                                        Prop?.setCurModule("SM")
                                                        let y = Prop?.curLesson;
                                                        // debugger;
                                                        Prop.setCurLesson({

                                                            id: oneLesson?.id, //7,
                                                            Title: "Resources",
                                                            Type: "FlipBook",
                                                            URL: oneLesson?.resource?.URL//"https://player.vimeo.com/video/817274947"
                                                        })


                                                    }}
                                                       className="d-flex align-items-center position-relative">
                                                        <i className="fa fa-file-pdf-o" style={{fontSize:12,marginRight: 7}}></i>
                                                        <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                        <strong className="text-body">{oneLesson?.resource?.Title}</strong>
                                    </span>
                                                    </a>
                                                </li>

                                    }

                                </ul>

                            </ul>

                        })
                    }







                        <ul style={{cursor:'pointer'}} className={`list-group-item   px-3 py-12pt
                        
                        ${
                            Prop?.mySurveyScores?.testCount ? 'list-group-item-secondary locked-link' : (
                                Prop?.curLockId <= lessonHelper.highestLockId(Lessons) ? 'list-group-item-danger' : (
                                    Prop?.mySurveyScores?.testCount ? 'list-group-item-success' : 'list-group-item-warning'
                                )
                            )
                        }
                         
                         `}>
                            <div
                                id=""
                                style={{cursor:'pointer'}}
                                className="d-flex align-items-center position-relative"
                                onClick={() => {
                                    if(Prop?.curLockId < lessonHelper.highestLockId(Lessons)){
                                        jAlert('You need to complete all modules first')
                                        return;
                                    }

                                    if(Prop?.mySurveyScores?.testCount){
                                        jAlert('You Have Completed this Check')
                                        return;
                                    }
                                    Prop?.setCurPlayer('CheckInstructions')

                                    }
                                }

                            >
                                <i className="fa fa-certificate p-1"></i>
                                <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                <strong className={`text-body ${Prop?.mySurveyScores?.testCount ? 'locked-link' : ''}`}  >Quick Check for MOs</strong>
                            </span>
                            </div>
                        </ul>


                    <hr/>



                    <ul style={{cursor:'pointer'}} className="list-group-item px-3 py-12pt bg-light">
                        <a id="" className="d-flex align-items-center position-relative" href="./faqs">
                            <i className="fa fa-question-circle p-1"></i>
                            <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                <strong className="text-body">Frequently Asked Questions</strong>
                            </span>
                        </a>
                    </ul>



                    {

                        (Prop?.curLockId > lessonHelper.highestLockId(Lessons) && Prop?.mySurveyScores?.testCount) && <ul style={{cursor:'pointer'}} className="list-group-item px-3 py-12pt bg-light">
                            <a
                                id=""
                               className="d-flex align-items-center position-relative"
                               download="download"
                               target="_blank"
                               href={`${API.Certificate}?key=${keya}&key2=${keyb}&./assets/certificate.pdf`}
                                   >
                                <i className="fa fa-certificate p-1"></i>
                                <span className="flex d-flex flex-column" style={{maxWidth:175}}>
                                <strong className="text-body">Download Certificate</strong>
                            </span>
                            </a>
                        </ul>
                    }




                </nav>
            </div>


            <div className="ps__rail-x" style={{left: 0, bottom: 0}}>
                <div className="ps__thumb-x" tabIndex="0" style={{left: 0, width: 0}}></div>
            </div>

            <div className="ps__rail-y" style={{top: 0, height: 873, right: 0}}>
                <div className="ps__thumb-y" tabIndex="0" style={{top: 0, height: 0}}></div>
            </div>
        </div>
    );
};

export default PlayerList;