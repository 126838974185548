import React, {useEffect, useState} from 'react';
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Overlay from "../Components/Overlay";
import Footer from "../Components/Footer";
import PlayerList from "../Components/PlayerList";
import VideoPlayer from "../Components/VideoPlayer";
import Pretester from "../Components/Pretester";
import Instruction from "../Components/Instruction";
import useLessons from "../DataFiles/Lessons";
import FlipBook from "../Components/FlipBook";
import ActivityPlayer from "../Components/ActivityPlayer";
import {Checker, getProfileInfo} from "../Queries/Sessions";
import TestResult from "../Components/TestResult";
import jAlert from "../Helpers/jAlert"
import $ from 'jquery';
import API from "../Queries/API";
import alertify from "alertifyjs";
import {lessonHelper} from "../Helpers/lessonHelpers";
import PreviewScreen from "../Components/PreviewScreen";
import Survey from "../Components/Survey";
import CheckInstructions from "../Components/CheckInstructions";

const TakeCourse = () => {
    const [showMenu, setShowMenu] = useState(false)
    const [curSubTitle, setCurSubTitle] = useState("")
    const [curTitle, setCurTitle] = useState("General Instructions")
    const [curPlayer, setCurPlayer] = useState("Instruction")
    // const [curPlayer, setCurPlayer] = useState("TestResult")
    const [curModule, setCurModule] = useState("SM");
    const [curLessons, setCurLessons] = useState({});
    const [curLesson, setCurLesson] = useState({});
    const [Activities, setActivities] = useState([])
    const [curQuestions, setCurQuestions] = useState([])
    const [userProfile, setUP] = useState({})
    const [curLockId, setCLI] = useState(0)
    const [MCHandle, setMCHandle] = useState(()=>{})
    const [Scores, setSC] = useState({}) //original scores
    const [mySurveyScores, setMYSC] = useState({})
    const [testType, setTT] = useState('');
    const [Started, setStarted] = useState(new Date())
    const [pretested, setPRT] = useState(null)
    const [ScoreData, setScoreData] = useState([])
    const [Surveys, setSurveys] = useState([])
    const [urlGetModule, setUGM] = useState(0)
    const [Avatar, setAvatar] = useState("");

    const {data:Lessons, isLoading, error} = useLessons()
    useEffect(()=>{
        // console.log({Lessons})
    },[Lessons])

    // console.log({curLockId})

/*
    const [Lessons, setLessons] = useState([])
    useEffect(()=>{
        setLessons(Lessons)
        console.log({ddd:data})
    },[data])
*/


    useEffect(()=>{
        // console.log({curModule})
       let m = Lessons?.find((i)=>i.id === curModule)
        if(m){
            setCurTitle(m?.title)
            setCurQuestions(m?.pretest)
            setCurLessons(m?.lessons)
            setActivities(m?.Activities)
            // console.log({m})
        }


    },[curModule])



    useEffect(()=>{


         /*   const url = new URL(window.location.href);
            const queryParams = new URLSearchParams(url.search);
            if (queryParams.has('module')) {
                const moduleValue = queryParams.get('module');
               setCurPlayer("PreviewScreen")
            }
*/
        // console.log({curLesson})
    },[curLesson])




    useEffect(()=>{
        let m = getProfileInfo()
        if(m?.uid){
            setAvatar(API.ImageStore+"/"+m?.uid+".png?"+Math.random())
        }
        // console.log({m})
        setUP(m)
    },[])

    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'getscores',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log({serverProgress:result})

                setCLI(result?.data?.progress ? result?.data?.progress : curLockId)
                setPRT(result?.data?.pretest ? result?.data?.pretest : pretested)
                setScoreData(result?.detail)


                //if I have taken survey, update my survey score
                if(result?.survey?.length > 0){
                    setMYSC(JSON.parse(result?.survey?.[0]?.surveydata))
                }
                //end of update survey scores

              /*  if(result?.data?.progress){
                    setCLI(result?.data?.progress)
                    setPRT(result?.data?.pretest)

                }*/
            })
            .catch(error => console.log('error', error));


    },[])
    useEffect(()=>{
        Checker()
    },[])

    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'survey',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${API.Lessons}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log({surveyresult:result})
                setSurveys(result)
            })
            .catch(error => console.log('error', error)).finally(()=>{
        });

    },[])


    const handleSaveProgress = ()=>{
        console.log({curLesson})
        if(curLesson?.lockId == curLockId || parseInt(curLesson?.lockId) == parseInt(curLockId)+1){

            let nextId = parseInt(curLockId)+1;

            var myHeaders = new Headers();
            myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
            myHeaders.append("Content-Type", "application/json");

            //getting time spent
            let timediff = (new Date() - Started)/1000;

            var raw = JSON.stringify({
                "perform": 'saveprogress',
                "progress": nextId,
                "timespent":timediff,

            });
            // return;
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(API.Scores, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log({syncprogress:result})
                    if(result?.data){
                        setCLI(nextId)
                        setStarted(new Date());
                        jAlert('Congratulations!<br/> You have unlocked the next task')
                    }
                })
                .catch(error => console.log('error', error));

        }
        // setCLI(curLockId+1)
    }
    const handleEnablePostTest = ()=>{

        const mapLocks = lessonHelper.mapLockId(Lessons)
        //current map grade
        let lockGrade = mapLocks?.find((oneGrade)=>{
            return (curLockId-1) < oneGrade?.maxLock
        })
        let istested = ScoreData?.[(lockGrade?.id - 1)]?.completedmodule

        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");

        //getting time spent
        let timediff = (new Date() - Started)/1000;

        var raw = JSON.stringify({
            "perform": 'enablePost',
            "module": lockGrade?.id,
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {



                console.log({enabledTest:result})
                alertify.alert('Mamta E-Learning','Post Test Enabled').set('onok',()=>{

                    //confirm from user
                    if(!window.confirm('Are You Sure you want to proceed with the test? \nYou cannot leave the test screen when you start')){
                        return;
                    }
                    $(".module-submenu").slideUp();
                    //end of user permission


                    setCurSubTitle('Post Test')
                    setCurPlayer('postTest')

                    return;
                    // window.location.reload()

                })

            })
            .catch(error => console.log('error', error));




    }

    const calcProgress =(RoundUp=false)=>{
        const mapLocks = lessonHelper.mapLockId(Lessons)
        // console.log({mapLocks})

        //current map grade
        let lockGrade = mapLocks?.find((oneGrade)=>{
            return (curLockId-1) < oneGrade?.maxLock
        })
        // console.log({lockGrade})
        let cur = curLockId<(lockGrade?.maxLock+1) ?curLockId : (curLockId-(lockGrade?.maxLock+1));

        //previous map grade
        const curIndex = mapLocks.findIndex((oneGrade2)=>{
            return (curLockId-1) < oneGrade2?.maxLock
        })
        let of = lockGrade?.maxLock;
        if(curIndex>0){
            cur = curLockId - mapLocks[curIndex-1].maxLock;
            of = mapLocks[curIndex].maxLock - mapLocks[curIndex-1].maxLock
        }

        //checking if test is taken
        let istested = ScoreData?.[(lockGrade?.id - 1)]?.completedmodule

        //end of checking if test is taken

        ///////////////////////////
        if(RoundUp){
            cur = Math.floor(cur)
            of = Math.floor(of)
        }

        // debugger;

        let perc = Math.round((cur/of)*100);
        return {
            cur,
            of,
            perc,
            istested,
        }

    }
    const [openMenu, setOpenMenu] = useState()

    useEffect(()=>{
        console.log({urlGetModule, curModule,openMenu})
    },[openMenu,curModule])
    const getActiveModule = ()=>{
        const mapLocks = lessonHelper.mapLockId(Lessons)
        let lockGrade = mapLocks?.find((oneGrade)=>{
            return (curLockId-1) < oneGrade?.maxLock
        })
        /*let istested = ScoreData?.[(lockGrade?.id - 1)]?.completedmodule
        debugger;*/
        return lockGrade?.id;
    }


    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default ">

                <div className="preloader">
                    <div className="sk-double-bounce">
                        <div className="sk-child sk-double-bounce1"></div>
                        <div className="sk-child sk-double-bounce2"></div>
                    </div>
                </div>

                <div className="mdk-header-layout js-mdk-header-layout">

                    <Header showMenu={setShowMenu}/>
                    <div className="row">
                        <div className="col-3" style={{paddingTop:85,maxWidth:300,backgroundColor:'#cde9a5',paddingRight:'unset'}}>
                            <PlayerList
                                Surveys={Surveys}
                                mySurveyScores={mySurveyScores}
                                setCurTitle={setCurTitle}
                                setCurQuestions={setCurQuestions}
                                curModule={curModule}
                                setCurModule={setCurModule}
                                setCurSubTitle={setCurSubTitle}
                                setCurPlayer={setCurPlayer}
                                Lessons={Lessons}
                                setCurLesson={setCurLesson}
                                curLesson={curLesson}
                                curLockId={curLockId}
                                curPlayer={curPlayer}
                                pretested={pretested}
                                istested={calcProgress()?.istested}
                                setOpenMenu={setOpenMenu}
                                urlGetModule={urlGetModule}
                                setUGM={setUGM}
                            />
                        </div>
                        <div className="col-9" style={{paddingTop:85}}>
                            <div className="mdk-header-layout__content page-content reset-content">
                                <div data-push data-responsive-width="768px" data-has-scrollable-region data-fullbleed
                                     className="mdk-drawer-layout js-mdk-drawer-layout">
                                    <div className="mdk-drawer-layout__content" data-perfect-scrollbar>

                                        <div className="app-messages__container d-flex flex-column h-100 pb-4">

                                                {curPlayer !== "sInstruction" &&
                                                    <div className="navbar navbar-light bg-white navbar-expand-sm navbar-shadow z-1"
                                                         id="messages-navbar">
                                                    <div className="container flex-wrap px-sm-0">
                                                        <div className="nav py-2">
                                                            <div className="nav-item d-flex align-items-center mr-3">
                                                                <div className="mr-3">
                                                                    <div className="avatar avatar-online avatar-sm">
                                                                        <img src={Avatar}
                                                                             alt="people"
                                                                             className="avatar-img rounded-circle"/>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-column"
                                                                     style={{maxWidth: 200, fontSize: 15}}>
                                                                    <strong
                                                                        className="text-body">{userProfile?.full_name}</strong>
                                                                    <span className="text-50 text-ellipsis">User</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="text-regular-caption m-2 mt-2" style={{display:['PreviewScreen','Instruction'].includes(curPlayer) ? 'none' : 'flex'}}>
                                                    <span className="tutor-progress-content text-dark d-xl-inline-block">
                                                        Your Progress: </span>
                                                                <span
                                                                    className="text-bold-caption"> {calcProgress(true).cur}</span> of <span
                                                                className="text-bold-caption">  {calcProgress(true).of} </span> ({calcProgress(true).perc}%)
                                                            </div>

                                                            <button className="btn btn-primary"
                                                                    // style={{display: curLockId > 0 || true ? 'block' : 'none'}}
                                                                    style={{display: ['Instruction','preTest','postTest','TestResult','PreviewScreen','CheckInstructions'].includes(curPlayer) || /*curLockId==17 */ calcProgress().cur == calcProgress().of ? 'none' : 'block'}}
                                                                    type="button" onClick={() => {
                                                                handleSaveProgress()
                                                            }}>Mark as Complete
                                                            </button>
                                                            <button
                                                                onClick={()=>handleEnablePostTest()}
                                                                className="btn btn-primary"
                                                                style=
                                                                    {{
                                                                        display:['posttest','TestResult'].includes(curPlayer) ? 'none' : (calcProgress().cur == calcProgress().of && calcProgress()?.istested == 0 &&  [/*urlGetModule*/getActiveModule()].includes(curModule) ? 'block' : 'none')
                                                                    }}
                                                            >
                                                                Enable Post Test {/*{getActiveModule()} {urlGetModule} {curModule}*/}
                                                            </button>
                                                            <button
                                                                onClick={()=>{
                                                                    console.log({curModule})
                                                                    $("#module"+0+"-submenu").slideDown('slow')
                                                                    // setOpenTab(0)
                                                                    setCurModule(1)
                                                                }}
                                                                className="btn btn-primary"
                                                                style={{display:(curPlayer=='Instruction' && isNaN(curModule)) ? 'block' : 'none'}}
                                                            >
                                                               Start Course
                                                            </button>


                                                            <button data-target="#messages-drawer"
                                                                    className="navbar-toggler d-block d-md-none ml-3 p-0"
                                                                    data-toggle="sidebar" type="button">
                                                                <i className="material-icons">people_outline</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    </div>
                                                }


                                            <div className="flex pt-2" style={{position: 'relative'}} data-perfect-scrollbar>
                                                <div className="container page__container page__container">

                                                    {/*__{
                                                        curPlayer
                                                    }__*/}


                                                    {curModule && <div className="card card-hr">
                                                        <div className="card-body d-flex align-items-center">

                                                            <div className="flex">

                                                                {
                                                                    !['PreviewScreen','CheckedInstructions'].includes(curPlayer) && (
                                                                       <>
                                                                           <h4 className="mb-0">{curTitle}</h4>
                                                                           <p className="text-50 mb-0">{curSubTitle}</p>
                                                                       </>
                                                                        )
                                                                }
                                                                {curPlayer === "PreviewScreen" && <PreviewScreen curTitle={curTitle} curModule={curModule} curLesson={curLesson} Lessons={Lessons}/>}
                                                                {curPlayer === "CheckInstructions" && <CheckInstructions setCurPlayer={setCurPlayer} setCurTitle={setCurTitle} />}

                                                            </div>
                                                        </div>
                                                    </div>}

                                                    {curPlayer === "Vimeo" && <VideoPlayer curLesson={curLesson} />}
                                                    {curPlayer === "FlipBook" && <FlipBook curLesson={curLesson} />}
                                                    {curPlayer === "Activity" && <ActivityPlayer curLesson={curLesson} />}
                                                    {curPlayer === "Instruction" && <Instruction curLesson={curLesson} />}


                                                    {(curPlayer === "preTest" || curPlayer === "postTest") &&  <Pretester testMod={curPlayer} Questions={curQuestions}  setCurPlayer={setCurPlayer} setSC={setSC} testType={testType} setTT={setTT} curLockId={curLockId} curModule={curModule} />}
                                                    {curPlayer === "Survey"  &&  <Survey Surveys={Surveys} />}

                                                    {curPlayer === "TestResult" &&  <TestResult  curModule={curModule}  testMod={curPlayer} Questions={curQuestions}  setCurPlayer={setCurPlayer} setCLI={setCLI} Scores={Scores} testType={testType} setCurSubTitle={setCurSubTitle} setCurModule={setCurModule} setCurLesson={setCurLesson} curLockId={curLockId}/>}

                                                </div>
                                            </div>

                                        </div>
                                    </div>




                                    <div className="mdk-drawer js-mdk-drawer" data-align="start" id="messages-drawer">
                                        <div className="mdk-drawer__content top-navbar">
                                            <div className="sidebar sidebar-right sidebar-light bg-white o-hidden">
                                                <div className="d-flex flex-column h-100 pt-4 vertical-tabs">

                                                    <div className="flex" data-perfect-scrollbar>
                                                        <div className="sidebar-heading">Substance Misuse</div>

                                                        <div className="list-group list-group-flush mb-3">
                                                            <nav>
                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a id="module1"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-caret-right p-1"></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 1</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module1-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module1"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Pre-Test</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module1"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Watch Video</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module1"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Flip Book</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>

                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module1"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                           <strong className="text-body">Post Test</strong>
                                                       </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>


                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module2"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 2</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module2-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module2"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Pre-Test</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module2"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Watch Video</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>

                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#" id="module2"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Flip Book</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module2"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                           <strong className="text-body">Post Test</strong>
                                                       </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>
                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module3"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 3</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module3-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module3"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Pre-Test</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module3"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Watch Video</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>

                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#" id="module3"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Flip Book</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module3"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                           <strong className="text-body">Post Test</strong>
                                                       </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module4"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 4</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module4-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module4"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Pre-Test</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module4"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Watch Video</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>

                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module4"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Flip Book</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module4"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                           <strong className="text-body">Post Test</strong>
                                                       </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module5"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 5</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module5-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Pre-Test </strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Watch Video</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Flip Book</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Post Test</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module6"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 6</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module6-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module6"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Pre-Test</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module6"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Watch Video</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module6"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Flip Book</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module6"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Post Test</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module7"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 7</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module7-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module7"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Pre-Test</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module7"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Watch Video</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module7"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Flip  Book</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>

                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module7"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Post Test</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module8"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 8</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module8-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Pre-Test</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Watch Video</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module5"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Flip Book</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module8"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Post Test</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module9"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 9</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module9-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module9"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Pre -Test</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module9"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Watch Video</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module9"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Flip Book</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module9"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Post Test</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>

                                                                <ul className="list-group-item px-3 py-12pt bg-light">
                                                                    <a href="#" id="module10"
                                                                       className="d-flex align-items-center position-relative">
                                                                        <i className="fa fa-lock" aria-hidden="true"
                                                                           style={{fontSize:12,marginTop:7}}></i>
                                                                        <span className="flex d-flex flex-column"
                                                                              style={{maxWidth:175}}>
                                                            <strong className="text-body">Modules 10</strong>
                                                        </span>
                                                                    </a>
                                                                    <ul id="module10-submenu" className="module-submenu">
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content1" id="module10"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Pre-Test</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content2" id="module10"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-video"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                    <strong className="text-body">Watch Video</strong>
                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content4" id="module10"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                                   <strong className="text-body">Flip Book</strong>
                                                               </span>
                                                                            </a>
                                                                        </li>
                                                                        <li style={{paddingBottom:6,marginTop:8}}>
                                                                            <a href="#content3" id="module10"
                                                                               className="d-flex align-items-center position-relative">
                                                                                <i className="fa fa-file"
                                                                                   style={{fontSize:12,marginTop:7}}></i>
                                                                                <span className="flex d-flex flex-column"
                                                                                      style={{maxWidth:175}}>
                                                               <strong className="text-body">Quick check for MOs</strong>
                                                           </span>
                                                                            </a>
                                                                        </li>


                                                                    </ul>

                                                                </ul>


                                                            </nav>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <Footer/>
                </div>

            </div>
        </div>

);
};

export default TakeCourse;