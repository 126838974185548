import React, {useEffect, useState} from 'react';
import $ from 'jquery'
import jAlert from "../Helpers/jAlert";
import API from "../Queries/API";
import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';
import {Button} from "@mui/material";
import {lessonHelper} from "../Helpers/lessonHelpers";
import useLessons from "../DataFiles/Lessons";


const getText = (htmlString)=>{
    let span = document.createElement('span');
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
};
const Survey = (Prop) => {
    console.log({tProp:Prop})
    // debugger;
    const [curQ, sCurQ] = useState(0);
    const [testBoard, setTB] = useState({})
    const [Started, setStarted] = useState(new Date())
    const [curModule, setCM] = useState(1);

    const {data:Lessons, isLoading, error} = useLessons()

    useEffect(()=>{
        setCM(Prop?.curModule)
        console.log("Curmod",Prop?.curModule)
    },[Prop])

    useEffect(()=>{
        // console.log({Prop})
        setTB({...testBoard,testCount:Prop?.Surveys?.length})

        return;
        /* if(Prop?.curLockId <= 17){
             setCM(1)
         }else{
             setCM(2)
         }*/



        // let T= setInterval(()=>{},1000)



    },[])



    const handleSubmitSurvey =  ()=>{

        // return;
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        //GETTING SCORES


        //HANDLES UPDATE PROGRESS
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        //getting time spent
        var rawp = JSON.stringify({
            "perform": 'savesurvey',
            "board": testBoard,
        });
        // debugger;
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: rawp,
            redirect: 'follow'
        };
        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                alert(result.message)
                window.location.href="./dashboard"
                // window.location.reload()
            })
            .catch(error => console.log('error', error));
    }

    const SelectedOption = ()=>{
        if($("#customChecka").is(':checked')){
            return 1
        }
        if($("#customCheckb").is(':checked')){
            return 2
        }
        if($("#customCheckc").is(':checked')){
            return 3
        }
        if($("#customCheckd").is(':checked')){
            return 4
        }
        if($("#customChecke").is(':checked')){
            return 5
        }
        if($("#customCheckf").is(':checked')){
            return 6
        }
        if($("#customCheckg").is(':checked')){
            return 7
        }
        if($("#customCheckh").is(':checked')){
            return 8
        }
        if($("#customChecki").is(':checked')){
            return 9
        }
        if($("#customCheckj").is(':checked')){
            return 10
        }
        return 0

    }



    return (
        <div className="cd" id="content1">
            <div className="page-separator">
                <div className="page-separator__text">Quick Checks</div>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">
                            <p style={{display: (typeof Prop?.Questions?.[curQ]?.correct == "string") ? "none": "block",fontStyle:'italic',color:'#FF0000',fontSize:'larger'}}><b>Instruction: </b>Tick the option that you find most appropriate for the following statements – (1) being the least appropriate and (5) being the most appropriate</p>
                            <div
                                className="d-flex align-items-center page-num-container mb-16pt">
                                <div className="page-num">{curQ+1}</div>
                                <h4>Question {curQ+1} of {Prop?.Surveys?.length}</h4>
                            </div>

                            <div dangerouslySetInnerHTML={ { __html: Prop?.Surveys?.[curQ]?.Question } } className="" style={{fontSize:20}}></div>





                            <div className="page-section" style={{paddingTop:'unset'}}>
                                {/*     <div className="page-separator">
                                    <div className="page-separator__text">Your Answer</div>
                                </div>*/}

                                {
                                    Prop?.Surveys?.[curQ]?.a && <div className="form-group">

                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customChecka" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customChecka"
                                                   className="custom-control-label">a). {Prop?.Surveys?.[curQ]?.a}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.b && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customCheckb" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckb"
                                                   className="custom-control-label">b). {Prop?.Surveys?.[curQ]?.b}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.c && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customCheckc" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckc"
                                                   className="custom-control-label">c). {Prop?.Surveys?.[curQ]?.c}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.d && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customCheckd" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckd"
                                                   className="custom-control-label">d). {Prop?.Surveys?.[curQ]?.d}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.e && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customChecke" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customChecke"
                                                   className="custom-control-label">e). {Prop?.Surveys?.[curQ]?.e}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.f && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckf" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckf"
                                                   className="custom-control-label">f). {Prop?.Surveys?.[curQ]?.f}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.g && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckg" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckg"
                                                   className="custom-control-label">g). {Prop?.Surveys?.[curQ]?.g}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.h && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckh" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckh"
                                                   className="custom-control-label">h). {Prop?.Surveys?.[curQ]?.h}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.i && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customChecki" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customChecki"
                                                   className="custom-control-label">i). {Prop?.Surveys?.[curQ]?.i}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Surveys?.[curQ]?.j && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckj" type={typeof Prop?.Surveys?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckj"
                                                   className="custom-control-label">j). {Prop?.Surveys?.[curQ]?.j}</label>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div
                                className="d-flex flex-column flex-sm-row flex-wrap align-items-center justify-content-center justify-content-md-start" style={{maxWidth:600,textAlign:'center'}}>
                                <a
                                    style={{display:curQ==0 ?'none' : 'block'}}
                                    href="#" onClick={()=>{
                                    curQ>0 && sCurQ(curQ-1)
                                }}
                                    className={`btn btn-outline-secondary flex mb-8pt mb-sm-0 mr-sm-16pt ${curQ==0 ? 'disabled' : ''}`}>Previous
                                    question</a>
                                <button type="button" className="btn btn-accent flex" onClick={()=>{

                                    if(!$("#customChecka").is(':checked') && !$("#customCheckb").is(':checked') && !$("#customCheckc").is(':checked') && !$("#customCheckd").is(':checked') && !$("#customChecke").is(':checked')   && !$("#customCheckf").is(':checked')  && !$("#customCheckg").is(':checked')  && !$("#customCheckh").is(':checked')  && !$("#customChecki").is(':checked')  && !$("#customCheckj").is(':checked')  ){
                                        jAlert("Please select an answer");
                                        return;
                                    }





                                    let m = testBoard;


                                    let QObj = {
                                        Q:getText(Prop?.Surveys?.[curQ]?.Question),
                                    }

                                    QObj.A = SelectedOption()




                                    m['Q'+(curQ+1)]= QObj;
                                    // debugger
                                    console.log({testBoard:m})
                                    setTB(m)


                                    if(curQ+2 > Prop?.Surveys?.length){
                                        // Prop?.setCurPlayer('')
                                        // Prop?.setCurSubTitle('')
                                        handleSubmitSurvey()

                                    }else{
                                        sCurQ(curQ+1)
                                        $(".chb").prop('checked',false)

                                    }
                                }}>Next question
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Survey;