import React, {useEffect, useState} from 'react';
import API from "../Queries/API";


const QAPage = ({story = ""})=>{
    console.log({story})


    return (
        <div className="cd" id="content">

            <div className="card">
                <div style={{padding:20}} dangerouslySetInnerHTML={ { __html: story?.content } }>

                    {/* {story === 'ronil' && <div>

                <div className="card-title" style={{padding:10}}>
                    Case Study of Ronil
                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>1) Why has Ronil started smoking?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li >Anxious about performance in exams, scores and good grades.</li>
                        <li >High parental expectation.</li>
                        <li >Lack of self-awareness and self-confidence</li>
                        <li >Stressed as what if could not perform as per everyone’s expectation.</li>
                        <li >False believe that cigarette smoking will relieve stress Vulnerable to peer group influence.</li>
                        <li >Looked for short cuts to address stress rather working on the root cause.</li>
                        <li >Easy availability of cigarettes and acceptability of smoking among friends/peer group.</li>
                        <li >All the above.</li>
                        </ul>
                    </div>


                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>2) Do you think smoking has helped reduce Ronil stress?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li >No. Smoking is not a solution for any problem.</li>
                        <li >No, the stress is due to unrealistic expectations and poor self-awareness and Ronil required counselling.</li>
                        <li >No, he believed his peers and friends are his well-wishers and better informed to deal with problems of his age which is not the ideal situation.</li>
                        <li >Nicotine acts on nAch receptors and causes the release of dopamine and other positive reinforcing hormones thus reducing anxiety and tension. But yes, it hijacks the reward pathway and causes dependence.</li>
                        </ul>
                    </div>

                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>3) What other substance (or forms) do adolescent take in a similar situation?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li >Tobacco (Gutka, Khaini, pan masala)</li>
                        <li >Alcohol</li>
                        <li >Cough syrups</li>
                        <li >Diluter</li>
                        <li >Cannabis</li>
                        <li >Inhalants</li>
                        <li >Cocaine</li>
                        <li >All the above</li>
                        </ul>
                    </div>
                </div>
            </div>}
            {story === 'mubin' && <div>

                <div className="card-title" style={{padding:10}}>
                    Case Study of Mubin
                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>1) Why dose Mubin feel left out by his classmate?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li>Thin built and short height (Negative body image and so lack of self-confidence).</li>
                        <li>New to the school, so not much friends Keeps himself busy with studies</li>
                        <li>Ridiculed or Bullied as “Padhaku Bachcha”.</li>
                        <li>All the above.</li>
                        </ul>
                    </div>


                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>2) Will smoking help him?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li>No, to impress and be accepted he can tell his peers that he could help them with their studies if they need help. This is his positive point that he can use to his advantage in being accepted in the peer.</li>
                        <li>No, smoking cannot help over-come body image issues or help increase self-confidence.</li>
                        <li>No, smoking will make him habitual and affect his health in a long run.</li>
                        <li>No, making impressions with smoking and similar habits fades soon and person is often left alone.</li>
                        <li>All the above.</li>
                        </ul>
                    </div>

                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>3) What are the other situations where peer influence may trigger substance misuse?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li>Disjointed family.</li>
                        <li>Lack of empathy by parents/guardians/caregivers.</li>
                        <li>Lack of parental/guardian guidance and support in situations of grief, anxiety, anger, any other emotional disturbances.</li>
                        <li>Low self-esteem and self-confidence.</li>
                        <li>High risks and vulnerability to negative influences from media, celebrities, adults and peers around.</li>
                        <li>Role modeling.</li>
                        <li>Tendency to experiment during adolescence phase.</li>
                        <li>All the above.</li>
                        </ul>
                    </div>
                </div>
            </div>}
            {story === 'santosh' && <div>

                <div className="card-title" style={{padding:10}}>
                    Case Study of Santosh
                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>1) What are the other ways of support to Santosh?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span><br/><br/>
                        <div style={{paddingLeft:"19px",fontSize:"14px"}}>Santosh can be supported through the doctors themselves as well as with the help of Santosh’s family, friends, teachers and others close to him. Creating a safety net is an important step as persons like Santosh needs not only the doctor but many more people in his life to win this battle against the substance misuse. Some of the ways to help him could be:</div>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li>Help him regain his self-esteem and confidence.</li>
                        <li>Reinforce his positive attributes.</li>
                        <li>Engage and involve him in school and family activities and events.</li>
                        <li>Be a continuous support to him to identify situations in which they are more likely to consume substances.</li>
                        <li>Be a continuous support to him to practice refusal skills and assertiveness.</li>
                        <li>Help him come out of internal fears or external threats, if any.</li>
                        <li>Do not isolate him, but make him feel welcomed among friends and family.</li>
                        <li>Help him avoid situations, where he is not able to control his behaviours, under influence of the substance consumed to prevent embarrassments and guilt later.</li>
                        <li>Help him get connected to experts (like counsellors, doctors, other relevant referral points including de-addiction peer group support networks).</li>
                        <li>Be extremely sensitive to the withdrawal symptoms, encourage him and counsel him on the management of symptoms of withdrawal.</li>
                        <li>Do share case studies / success stories of those who have overcome the problem in past.</li>
                        <li>Help him make achievable goals for prevention of substance misuse.</li>
                        <li>Reward him for his intentions and efforts towards quitting the substance misuse, irrespective of how small the effort is.</li>
                        <li>While trying to be a support, one should never.</li>
                        <li>Argue, quarrel, repeatedly suspect them.</li>
                        <li>Justify misuse of substance / take responsibility of covering up for the consequences.</li>
                        <li>Discuss previous misuse problems with others.</li>
                        </ul>
                    </div>


                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <b style={{paddingLeft:"19px"}}>2) What are some possible challenges you may face while working with adolescent substance use in AFHC?</b><br/><br/>
                        <span style={{paddingLeft:"19px"}}>Suggested answers:</span><br/><br/>
                        <div style={{paddingLeft:"19px",fontSize:"14px"}}>The challenges would vary depending upon the client, his/her background, existing family and social support systems, willingness to participate actively in the treatment, resource availability etc.</div>
                        <ul className="px-3 py-12pt forced-padding33" style={{fontSize:14,fontWeight:100}}>
                        <li>Lack of patience.</li>
                        <li>Low self-esteem and self-confidence.</li>
                        <li>Broken families or non-supportive family.</li>
                        <li>Poor social networks.</li>
                        <li>Social-stigma and lack of resources and time to reach out to family, school and friends.</li>
                        <li>Inconsistent visit by adolescent to AFHC or drop out/ loss to follow-up.</li>
                        <li>Substance misuse in family and hence easy access to substance or no restrictions.</li>
                        <li>Social stigma preventing reaching out for help to de-addiction centres/experts</li>

                        </ul>
                    </div>

                </div>
            </div>}
            {story === 'rajurajiv' && <div>

                <div className="card-title" style={{padding:10}}>
                    Raju And Rajiv Case Study
                </div>
                <div>
                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                                              <div style={{paddingLeft:"19px",fontSize:"14px"}}>

                            <div>
                                <ul>
                                    <li>
                                        <b>What do you think are the concerns?</b>
                                    </li>
                                </ul>
                                <b style={{textDecoration:'underline'}}>Raju Case Study:</b>
                                <p>
                                    <b>Ans:</b> Based on the history and presentation, Raju seems to be suffering from worm infestation and anaemia due to it. Moreover, we also see the unhealthy eating habit practiced by Raju which contributes to malnutrition. It is a matter of concern as this leads to poor general health and impairs work capacity.
                                </p>

                                <p>
                                    <b style={{textDecoration:'underline'}}>Rajiv Case study:</b><br/>
                                    <b>Ans:</b> Rajiv is 13-year old adolescent with a respiratory infection. This is concerning as this could be tuberculosis leading to malnutrition evidenced by stunting, thin build, and pallor.
                                </p>

                                <p>
                                    <ul>
                                        <li>
                                            <b>How can one approach the adolescent who has come to the clinic? </b> <br/>
                                            <b>Ans:</b> Nutritional concerns in adolescents are multifactorial and therefore approaches to adolescents therefore must be customized accordingly. Broadly as a medical officer four approaches can be used: <br/><br/>
                                            <div style={{paddingLeft:20,paddingBottom:20}}>
                                                1. Nutritional assessment <br/>
                                                2. Management of nutrition issues and educating adolescents about nutrition and a balanced diet<br/>
                                                3. Capacity building of paramedical staff to recognize and respond to nutritional concerns<br/>
                                                4. Working with families and communities
                                            </div>

                                        </li>
                                        <li>
                                            <b>What are the first steps that can be taken?</b> <br/>
                                            <b>Ans:</b> First step that can be taken is nutritional assessment. This includes 3 things: Complete history taking, Physical examination, and Laboratory test. Give deworming tablet and manage the hydration by providing ORS in Raju’s case while providing detailed investigation advice such as X-ray and sputum test and others to rule out TB.
                                        </li>
                                    </ul>
                                </p>

                            </div>

                    </div>
                    </div>

                </div>
            </div>}
            {story === 'sumit' && <div>

                <div className="card-title" style={{padding:10}}>
                    Role play Sumit 15-year-old boy
                </div>
                <div>
                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <div style={{paddingLeft:"19px",fontSize:"14px"}}>
                            <ul>
                                <li>
                                    <p>
                                        <b>Why is Sumit feeling weak and tired most of the time?</b> <br/>
                                        <b>Ans:</b> Let’s analyze the case study to answer this question. Sumit is a 15-year-old adolescent boy in the growing phase. He is performing household work and going to school also. The nutrition requirement for growth and these activities is not fulfilled by the diet he is taking. Further, he also suffered from malaria. So Sumit is probably suffering from anaemia due to which he is feeling weak and tired most of the time.
                                        <br/></p>
                                </li>
                                <li>
                                    <p>
                                        <b>Do you find that other adolescent girls and boys also have similar symptoms? Why? </b> <br/>
                                        <b>Ans:</b>is is best answered based on the experience. Many other adolescent girls and boys do have similar symptoms because of inadequate dietary intake. Also, malaria is also a common cause of anaemia in our country. So many of us have definitely seen similar cases.
                                        <br/>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>What steps can be taken to maintain the health of Sumit?</b>
                                        <br/>
                                        <b>Ans:</b> Detailed nutrition counseling is required for Sumit and his parents. He also needs further clinical management to prevent complications of anaemia.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>What has possibly happened to Sumit?</b><br/>
                                        <b>Ans:</b> Looking at the symptoms like weakness, restlessness, dizziness, and irritability etc and the dietary pattern (Cereal base diet with less or no micro nutrients content) we can say Sumit is suffering from Anemia.

                                    </p>
                                </li>
                            </ul>

                        </div>
                    </div>

                </div>
            </div>}
            {story === 'divya' && <div>

                <div className="card-title" style={{padding:10}}>
                    Divya’s story
                </div>
                <div>

                    <div className="card-body align-items-center" style={{fontSize:16,margin:20}}>
                        <div style={{paddingLeft:"19px",fontSize:"16px"}}>
                            <ul>
                                <li>
                                    <p>
                                        <b>What can you do as a Medical Officer: Responding to Divya</b><br/>

                                        <b>Ans:</b>As Medical officers, we must follow eight steps to respond to Divya:
                                        <div style={{paddingLeft:"19px",fontSize:"16px"}}>
                                            1.	Presentation of Divya <br/>
                                            2.	Take relevant history<br/>
                                            3.	Do an additional assessment<br/>
                                            4.	Do a Physical Examination<br/>
                                            5.	Perform Laboratory tests<br/>
                                            6.	Talk to Divya<br/>
                                            7.	Work with Divya’s parents<br/>
                                            8.	Provide any additional support as required
                                        </div>

                                    </p>

                                </li>
                                <li>
                                    <p>
                                        <b>Have you seen similar nutritional concerns in AFHC?</b><br/>
                                        <b>Ans: </b> As a Medical Officer such cases of nutritional concerns are often observed. The answer is subjective and may vary.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>What are the other ways of providing support to Divya?<br/>
                                        Ans:</b> These are mostly covered in the 8 points above.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>What are some of the possible challenges you may face while working with adolescent nutritional concerns and anaemia in the AFHC?</b><br/>

                                        1. Adolescent Malnutrition (Undernutrition/obesity)<br/>
                                        2. Fast food consumption (Unhealthy Eating Choices)<br/>
                                        3. Concerns of body image<br/>
                                        4. Gender issues<br/>
                                        5. Socio-economic and cultural factors<br/>
                                        6. Environmental factors<br/>
                                        7. Media promotion of unhealthy ideas of ideal body/skin
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>What are your key learnings about nutritional concerns in adolescents and ways of addressing the same, based on this module? </b><br/>
                                        Nutritional concerns in adolescents is multifactorial as mentioned in the previous question and therefore address is accordingly customized. Broadly as a medical officer four approaches can be used:<br/>

                                        1. Nutritional assessment<br/>
                                        2. Management of nutrition issues and educating adolescents about nutrition and a balanced diet<br/>
                                        3. Capacity building of paramedical staff to recognize and respond to nutritional concerns<br/>
                                        4. Working with families and communities<br/>

                                    </p>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
            </div>}*/}

                </div>

            </div>
        </div>

    )
}
const ActivityPlayer = (Prop) => {

    console.log({ActivityProp:Prop})
    const [textContent, setTC] = useState({})
    useEffect(()=>{
        if(Prop?.curLesson?.Type=="Page"){
                var myHeaders = new Headers();
                myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                    "perform": 'content',
                });
                // return;
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${API.Lessons}?content_id=${Prop?.curLesson?.Story}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log({result})
                        setTC(result)
                    })
                    .catch(error => console.log('error', error));
        }
    },[Prop?.curLesson])


    return (
        <div>
            {
                // Prop?.curLesson?.Type == "Page" ? <QAPage story={Prop?.curLesson?.Story}/> : <iframe src={Prop?.curLesson?.URL} width="100%" frameBorder="0" style={{minHeight:500}}>Browser not compatible.</iframe>
                Prop?.curLesson?.Type == "Page" ? <QAPage story={textContent}/> : <iframe src={Prop?.curLesson?.URL} width="100%" frameBorder="0" style={{minHeight:500,marginTop:'unset'}}>Browser not compatible.</iframe>
            }

        </div>

    );
};

export default ActivityPlayer;