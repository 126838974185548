import React, {useState} from 'react';
import {Link} from "react-router-dom";
import API from '../Queries/API'
import HeaderOut from "../Components/HeaderOut";
import Footer from "../Components/Footer";
import {Checker} from "../Queries/Sessions";


const Login = () => {
    const [loading, setL] = useState(false)
    const [username, setU] = useState('');
    const [password, setP] = useState('')
    const HandleLogin = ()=>{
        if(!username || !password){
            alert("Username and Password is mandatory");
            return;
        }
        setL(true)
        let data = {
            useremail:username,
            password
        }
        fetch(API.Login,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify(data)
        }).then((response)=> response.json())
            .then((rsp)=>{
                console.log({rsp})
                if(rsp?.status === false){
                    alert(rsp?.message)
                    return;
                }
                if(rsp?.token){
                    localStorage.setItem('usertoken',rsp?.token)

                    Checker((x)=>{
                        if(!Boolean(x?.state_id) || !Boolean(x?.district_id)){
                            console.log("Profile Not Updated");
                            window.location.href='./edit-profile'
                        }else{
                            console.log("Profile Up to Date");
                            window.location.href='./dashboard'
                        }
                    })
                    //
                    return;
                }
                alert('Unable to connect');



            })
            .finally(()=>{
                setL(false)
            })
    }
    return (
        <div className="layout-sticky-subnav layout-default ">

            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <div className="mdk-header-layout js-mdk-header-layout" style={{paddingTop: 80,backgroundColor:'#e4f4ca'}}>

                <HeaderOut/>

                <div className="mdk-header-layout__content page-content" style={{paddingBottom: '80px'}}>

                    <div className="pt-132pt pt-sm-64pt pb-32pt">
                        <div className="container page__container nopad" style={{backgroundColor:'#fff',width:400,paddingTop:20}}>
                            <form action="#" className="col-md-11 p-0 mx-auto">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="email">Email:</label>
                                    <input onChange={(v)=> {
                                        setU(v.target.value)
                                    }} value={username} id="email" type="text" className="form-control"
                                           placeholder="Your email address ..."/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="password">Password:</label>
                                    <input id="password" type="password" className="form-control"
                                           onChange={(v)=> {
                                               setP(v.target.value)
                                           }}
                                           placeholder="Your first and last name ..."/>
                                        {/* <p className="text-right"><Link to="#./reset-password" className="small">Forgot
                                            your password?</Link></p> */}
                                </div>
                            </form>
                            <div className="text-center">
                                <button type="button" className="btn btn-green btn-block" onClick={()=>HandleLogin()}>
                                    {loading && <i className="fa fa-spinner fa-spin"></i>}
                                    Login</button>
                            </div>

                        </div>
                    </div>

                </div>

               {/* <footer>

                    <div className="js-fix-footer bg-white border-top-2" data-domfactory-upgraded="fix-footer"
                         style={{opacity: 1}}>
                        <div className="container page-section py-lg-48pt">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                            <h4 className="text-70">Quick Link</h4>
                                            <nav className="nav nav-links nav--flush flex-column">
                                                <Link className="nav-link" to="#">Terms & Conditions</Link>
                                                <Link className="nav-link" to="#">Privacy Policy</Link>

                                            </nav>
                                        </div>
                                        <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                            <h4 className="text-70">Courses</h4>
                                            <nav className="nav nav-links nav--flush flex-column">
                                                <Link className="nav-link" to="#">Program 1</Link>
                                                <Link className="nav-link" to="#">Program 2</Link>
                                                <Link className="nav-link" to="#">Program 3</Link>
                                                <Link className="nav-link" to="#">Program 4</Link>
                                            </nav>
                                        </div>
                                        <div className="col-6 col-md-4 mb-32pt mb-md-0">
                                            <h4 className="text-70">Community</h4>
                                            <nav className="nav nav-links nav--flush flex-column">
                                                <Link className="nav-link" to="#">Discussions</Link>
                                                <Link className="nav-link" to="#">Ask Question</Link>
                                                <Link className="nav-link" to="#">Student Profile</Link>
                                                <Link className="nav-link" to="#">Instructor Profile</Link>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 text-md-right">
                                    <p className="text-70 brand justify-content-md-end">
                                        <img className="brand-icon" src="assets/images/logoblack.png" width="200"
                                             alt="Tutorio"/>
                                    </p>
                                    <p className="text-muted mb-0 mb-lg-16pt">eLearning Platform for Medical Officers,
                                        ANMs, Peer Educators cover health related education programs with
                                        certifications.</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-footer page-section py-lg-32pt">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-2 col-sm-4 mb-24pt mb-md-0">

                                    </div>
                                    <div className="col-md-6 col-sm-4 mb-24pt mb-md-0 d-flex align-items-center">
                                        <Link to="" className="btn btn-outline-white">English <span
                                            className="icon--right material-icons">arrow_drop_down</span></Link>
                                    </div>
                                    <div className="col-md-4  text-md-right">

                                        <p className="text-black-50 mb-0">Copyright 2023 © All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                <Footer/>

            </div>


            <div className="mdk-drawer js-mdk-drawer" id="default-drawer">
                <div className="mdk-drawer__content">
                    <div className="sidebar sidebar-dark-dodger-blue sidebar-left" data-perfect-scrollbar>


                        <div className="d-flex align-items-center navbar-height">
                            <form action="fixed-index.html"
                                  className="search-form search-form--black mx-16pt pr-0 pl-16pt">
                                <input type="text" className="form-control pl-0" placeholder="Search"/>
                                    <button className="btn" type="submit"><i className="material-icons">search</i>
                                    </button>
                            </form>
                        </div>


                        <Link to="index.html" className="">
                            <img src="assets/images/logo/logo.png" className="img-fluid" alt="logo"/>
                        </Link>


                        <div className="sidebar-heading">Student</div>

                        <div className="sidebar-heading">Instructor</div>
                        <ul className="sidebar-menu">


                            <li className="sidebar-menu-item">
                                <Link className="sidebar-menu-button" to="fixed-instructor-dashboard.html">
                                    <span
                                        className="material-icons sidebar-menu-icon sidebar-menu-icon--left">school</span>
                                    <span className="sidebar-menu-text">Instructor Dashboard</span>
                                </Link>
                            </li>
                            <li className="sidebar-menu-item">
                                <Link className="sidebar-menu-button" to="fixed-instructor-courses.html">
                                    <span
                                        className="material-icons sidebar-menu-icon sidebar-menu-icon--left">import_contacts</span>
                                    <span className="sidebar-menu-text">Manage Courses</span>
                                </Link>
                            </li>
                            <li className="sidebar-menu-item">
                                <Link className="sidebar-menu-button" to="fixed-instructor-quizzes.html">
                                    <span
                                        className="material-icons sidebar-menu-icon sidebar-menu-icon--left">help</span>
                                    <span className="sidebar-menu-text">Manage Quizzes</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <script src="assets/vendor/jquery.min.js"></script>

                <script src="assets/vendor/popper.min.js"></script>
                <script src="assets/vendor/bootstrap.min.js"></script>

                <script src="assets/vendor/perfect-scrollbar.min.js"></script>

                <script src="assets/vendor/dom-factory.js"></script>

                <script src="assets/vendor/material-design-kit.js"></script>

                <script src="assets/vendor/fix-footer.js"></script>

                <script src="assets/js/app.js"></script>

            </div>
        </div>
    );
};

export default Login;