import React, {useEffect, useState} from 'react';
import $ from 'jquery'
import jAlert from "../Helpers/jAlert";
import API from "../Queries/API";
import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';
import {Button} from "@mui/material";
import {lessonHelper} from "../Helpers/lessonHelpers";
import useLessons from "../DataFiles/Lessons";


const getText = (htmlString)=>{
    let span = document.createElement('span');
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
};
const Pretester = (Prop) => {
    console.log({tProp:Prop})
    // debugger;
    const [curQ, sCurQ] = useState(0);
    const [testBoard, setTB] = useState({})
    const [Started, setStarted] = useState(new Date())
    const [showMenu, setShowMenu] = useState(false)
    const [curModule, setCM] = useState(1);

    const {data:Lessons, isLoading, error} = useLessons()


    useEffect(()=>{
        setCM(Prop?.curModule)
        console.log("Curmod",Prop?.curModule)
    },[Prop])

    useEffect(()=>{
        // console.log({Prop})
        setTB({...testBoard,testCount:Prop?.Questions?.length})

return;
       /* if(Prop?.curLockId <= 17){
            setCM(1)
        }else{
            setCM(2)
        }*/



        // let T= setInterval(()=>{},1000)



    },[])



    const handleSubmitPretest =  ()=>{

        // return;
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        //GETTING SCORES
        let cnt = 0;
        for(let i=1; i<=testBoard?.testCount; i++){
            if(testBoard['Q'+i]?.A === 'pass'){
                cnt++;
            }
        }

        const progressCNT2 = ()=>{

            console.log(lessonHelper.mapLockId(Lessons))
            console.log(curModule)

            // debugger;


            const maxLock = lessonHelper.mapLockId(Lessons).find((x,i)=>{
                return x?.id == (curModule)
            }).minLock

            //this condition only for pretest
            return maxLock;
            //if post test, remove  return maxLock
            // debugger;

            //no need to check if user scored 60%
            //next lines are not necessary
            // if((cnt/testBoard?.testCount)*100 >= 60){
            if((cnt/testBoard?.testCount)*100 >= API.MIN_SCORE){
                return maxLock+1;
                // return curModule === 1 ? 18 : 30;
            }else{
                return maxLock;
                // return curModule === 1 ? 17 : 29;
            }
        }



        //getting time spent
        let timediff = (new Date() - Started)/1000;

        //HANDLES UPDATE PROGRESS
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        //getting time spent
        var raw1 = JSON.stringify({
            "perform": 'saveprogress',
            "progress": progressCNT2(),
            "timespent":timediff,
        });
        // debugger;
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw1,
            redirect: 'follow'
        };
        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({syncprogress:result})
                if(result?.data){
                    callbak();
                }
            })
            .catch(error => console.log('error', error));



        //END OF UPDATE PROGRESS



        function callbak(){
            //END OF GETTING SCORES
            var raw = JSON.stringify({
                "type": Prop?.testMod.toLowerCase(),//"pretest",
                "perform": "save",
                "score": cnt,
                "total": testBoard?.testCount,
                "testboard": testBoard,
                "timespent":timediff,
                "moduleId":curModule,
                "progress":progressCNT2(),
            });

            // console.log({raw})
            // return;
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(API.Scores, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    alertify.alert('RKSK E-Learning',result.message).set('onok',()=>{
                        Prop?.setCurPlayer('TestResult')
                    })
                    Prop?.setSC({
                        total: testBoard?.testCount,
                        attempted: testBoard?.testCount,
                        correct:cnt,
                        incorrect:(testBoard?.testCount-cnt),
                        percentage:((cnt/testBoard?.testCount)*100)
                    })
                })
                .catch(error => console.log('error', error));
        }


    }
    const handleSubmitPosttest =  ()=>{

        // return;
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        //GETTING SCORES
        let cnt = 0;
        for(let i=1; i<=testBoard?.testCount; i++){
            if(testBoard['Q'+i]?.A === 'pass'){
                cnt++;
            }
        }




        const progressCNT = ()=>{
            // const mapLock = lessonHelper.mapLockId(Lessons)
            // mapLock?.sort((a, b) => (a.id > b.id) ? 1: -1)
            // const mapIndex = mapLock.findIndex((oneLock)=>{
            //     return Prop.curLockId >= oneLock.minLock && Prop?.curLockId <= oneLock?.maxLock
            // })


            console.log(lessonHelper.mapLockId(Lessons))
            console.log(curModule)
            // debugger;
            // const maxLock = lessonHelper.mapLockId(Lessons)[curModule].maxLock
            const maxLock = lessonHelper.mapLockId(Lessons).find((x,i)=>{
                return x?.id == (curModule)
            }).maxLock


            // debugger;
            if((cnt/testBoard?.testCount)*100 >= API.MIN_SCORE){
                return maxLock+1;
                // return curModule === 1 ? 18 : 30;
            }else{
                return maxLock;
                // return curModule === 1 ? 17 : 29;
            }
        }


        //getting time spent
        let timediff = (new Date() - Started)/1000;

        // console.log({curModule})
// debugger;
// return;


        //HANDLES UPDATE PROGRESS
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");


        //get progress
        let Prgrss = progressCNT();
        if((cnt/(testBoard?.testCount))*100 >= API.MIN_SCORE){
            Prgrss = Prgrss-0.4
        }
        //end progress

        //getting time spent
        var raw1 = JSON.stringify({
            "perform": 'saveprogress',
            // "progress": progressCNT(),
            "progress": Prgrss,// progressCNT()-0.4,
            "timespent":timediff,
        });
        // debugger;
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw1,
            redirect: 'follow'
        };
        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({syncprogress:result})
                if(result?.data){
                   callbak();
                }
            })
            .catch(error => console.log('error', error));



        //END OF UPDATE PROGRESS



        function callbak(){
            //END OF GETTING SCORES
            // debugger;
            var raw = JSON.stringify({
                "type": 'posttest',//"pretest",
                "perform": "save",
                "score": cnt,
                "total": testBoard?.testCount,
                "testboard": testBoard,
                "timespent":timediff,
                "moduleId":curModule,
                // "progress":progressCNT()
            });

            // console.log({raw})
            // debugger;
            // return;
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(API.Scores, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    alertify.alert(result.message).set('onok',()=>{
                        Prop?.setTT('postTest')
                        Prop?.setCurPlayer('TestResult')
                    })
                    Prop?.setSC({
                        total: testBoard?.testCount,
                        attempted: testBoard?.testCount,
                        correct:cnt,
                        incorrect:(testBoard?.testCount-cnt),
                        percentage:((cnt/testBoard?.testCount)*100)
                    })
                })
                .catch(error => console.log('error', error));
        }







    }



    return (
        <div className="cd" id="content1">
            <div className="page-separator">
                <div className="page-separator__text">Test</div>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="">

                            <div
                                className="d-flex align-items-center page-num-container mb-16pt">
                                <div className="page-num">{curQ+1}</div>
                                <h4>Question {curQ+1} of {Prop?.Questions?.length}</h4>
                            </div>

                            <div dangerouslySetInnerHTML={ { __html: Prop?.Questions?.[curQ]?.Question } } className="" style={{fontSize:20}}></div>

                            <p style={{display: (typeof Prop?.Questions?.[curQ]?.correct == "string") ? "none": "block",fontStyle:'italic',fontSize:'small',color:'#FF0000'}}>Note: This question contains multiple answers.</p>



                            <div className="page-section" style={{paddingTop:'unset'}}>
                           {/*     <div className="page-separator">
                                    <div className="page-separator__text">Your Answer</div>
                                </div>*/}

                                {
                                    Prop?.Questions?.[curQ]?.a && <div className="form-group">

                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customChecka" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customChecka"
                                                   className="custom-control-label">a). {Prop?.Questions?.[curQ]?.a}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.b && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customCheckb" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckb"
                                                   className="custom-control-label">b). {Prop?.Questions?.[curQ]?.b}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.c && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customCheckc" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckc"
                                                   className="custom-control-label">c). {Prop?.Questions?.[curQ]?.c}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.d && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer" id="customCheckd" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckd"
                                                   className="custom-control-label">d). {Prop?.Questions?.[curQ]?.d}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.e && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customChecke" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customChecke"
                                                   className="custom-control-label">e). {Prop?.Questions?.[curQ]?.e}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.f && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckf" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckf"
                                                   className="custom-control-label">f). {Prop?.Questions?.[curQ]?.f}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.g && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckg" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckg"
                                                   className="custom-control-label">g). {Prop?.Questions?.[curQ]?.g}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.h && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckh" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckh"
                                                   className="custom-control-label">h). {Prop?.Questions?.[curQ]?.h}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.i && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customChecki" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customChecki"
                                                   className="custom-control-label">i). {Prop?.Questions?.[curQ]?.i}</label>
                                        </div>
                                    </div>
                                }
                                {
                                    Prop?.Questions?.[curQ]?.j && <div className="form-group">
                                        <div className="custom-control custom-radio">
                                            <input name="answer"  id="customCheckj" type={typeof Prop?.Questions?.[curQ]?.correct == "string" ? "radio" : "checkbox"}
                                                   className="custom-control-input chb"/>
                                            <label htmlFor="customCheckj"
                                                   className="custom-control-label">j). {Prop?.Questions?.[curQ]?.j}</label>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div
                                className="d-flex flex-column flex-sm-row flex-wrap align-items-center justify-content-center justify-content-md-start" style={{maxWidth:600,textAlign:'center'}}>
                                <a
                                    style={{display:curQ==0 ?'none' : 'block'}}
                                    href="#" onClick={()=>{
                                    curQ>0 && sCurQ(curQ-1)
                                }}
                                   className={`btn btn-outline-secondary flex mb-8pt mb-sm-0 mr-sm-16pt ${curQ==0 ? 'disabled' : ''}`}>Previous
                                    question</a>
                                <button type="button" className="btn btn-accent flex" onClick={()=>{

                                    if(!$("#customChecka").is(':checked') && !$("#customCheckb").is(':checked') && !$("#customCheckc").is(':checked') && !$("#customCheckd").is(':checked') && !$("#customChecke").is(':checked')   && !$("#customCheckf").is(':checked')  && !$("#customCheckg").is(':checked')  && !$("#customCheckh").is(':checked')  && !$("#customChecki").is(':checked')  && !$("#customCheckj").is(':checked')  ){
                                        jAlert("Please select an answer");
                                        return;
                                    }





                                    let correctOption = Prop?.Questions?.[curQ]?.correct;
                                    let m = testBoard;


                                    let QObj = {
                                        Q:getText(Prop?.Questions?.[curQ]?.Question),
                                    }


                                    if(typeof Prop?.Questions?.[curQ].correct != "string"){
                                        // debugger;
                                        let sAnswer = Prop?.Questions?.[curQ].correct.sort()
                                        // debugger;
                                        let tAnswer = JSON.stringify(sAnswer)

                                        let cAnswer = [];
                                        let Letters = [...Array(10)].map((_, i) => String.fromCharCode(i + 97))
                                        Letters.forEach((v,k)=>{
                                            if($("#customCheck"+v).is(':checked')){
                                                cAnswer.push(v);
                                            }
                                        })
                                        let sCAnser = cAnswer.sort();
                                        let sTAnser = JSON.stringify(sCAnser);
                                        console.log({tAnswer,sTAnser})


                                        QObj.A = tAnswer == sTAnser ? 'pass' : 'fail';
                                    }else{
                                        QObj.A = $("#customCheck"+correctOption).is(':checked') ? 'pass' : 'fail'
                                    }





                                    m['Q'+(curQ+1)]= QObj;
                                    console.log({testBoard:m})
                                    setTB(m)


                                    if(curQ+2 > Prop?.Questions?.length){
                                        Prop?.setCurPlayer('')
                                        // Prop?.setCurSubTitle('')
                                        if(Prop?.testMod == 'preTest'){
                                            handleSubmitPretest()

                                        }else{
                                            handleSubmitPosttest();
                                            return;
                                        }
                                    }else{
                                        sCurQ(curQ+1)
                                        $(".chb").prop('checked',false)

                                    }
                                }}>Next question
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Pretester;