import React, {useEffect, useState, lazy} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Overlay from "../Components/Overlay";
import {getProfileInfo,Checker} from '../Queries/Sessions';
import API from "../Queries/API";
import alertify from 'alertifyjs'
import 'alertifyjs/build/css/alertify.css';
import $ from 'jquery';
import ProfilePicture from "../Components/ProfilePicture";


const EditProfile = () => {

    const [showMenu, setShowMenu] = useState(false)
    const [States, setStates] = useState([]);
    const [Districts, setDS] = useState([]);
    const [Blocks, setBLK] = useState([]);
    const [EduQuals, setEdus] = useState([]);
    const [Designations, setDesignations] = useState([]);

    const [uProfile, setUP] = useState({})
    const [tProfile, setTP] = useState({})
    const [agreed, setAG] = useState(true)
    useEffect(()=>{
        //checking logged
        console.log('before checker')
        Checker();
        console.log('after checker')

    },[])
    useEffect(()=>{
        let m = getProfileInfo()
        console.log({m})
        setTP(m)
        setUP(m)



        //get states
        fetch(API.getState,{
            method: 'GET',
            // headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        })
            .then((response)=>response.json())
            .then((resp)=> {
                console.log({resp})
                setStates(resp.states)
            })
            .then(()=>{
                handleStateChange(m?.state_id,()=>{
                    handleDistrictChange(m?.district_id)
                })
            });


        //get educational qualifications
        fetch(API.getEducation,{
            method: 'GET',
            // headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        })
            .then((response)=>response.json())
            .then((resp)=>setEdus(resp.education));


        //get designations
        fetch(API.getDesignation,{
            method: 'GET',
            // headers: myHeaders,
            // body: raw,
            redirect: 'follow'
        })
            .then((response)=>response.json())
            .then((resp)=>setDesignations(resp.designation));


    },[])
    useEffect(()=>{
        console.log({States})
    },[States])
    useEffect(()=>{
        handleEQSwitch()
        handlePSTSwitch()
    },[tProfile])



    const handleStateChange = (x,next=false)=>{
        console.log({x})
        setTP({...tProfile,state_id:x})
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            "stateId": x,
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(API.getDistrict, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({districts:result})
                // setTP({...tProfile,district_id:result?.district})
                setDS(result?.district)
            })
            .catch(error => console.log('error', error))
            .finally(next && next());
    }
    const handleDistrictChange = (x,next=false)=>{
        // setMD(x)
        setTP({...tProfile,district_id:x})
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw2 = JSON.stringify({
            // "stateId": myState,
            "stateId": tProfile?.state_id,
            "districtId": x,
        });
        // console.log({raw2})
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw2,
            redirect: 'follow'
        };
        fetch(API.getBlocks, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({blocks:result})
                setBLK(result?.blocks)
            })
            .catch(error => console.log('error', error))
            .finally(()=>{
                next && next()
            });
    }
    const handleEQSwitch = ()=>{
        if($("#eqselect").val() === '7'){
            $("#eqgrpdiv").addClass("input-group");
            $("#eqtext").show();
        }else{
            $("#eqgrpdiv").removeClass("input-group");
            $("#eqtext").hide();
        }

    }
    const handlePSTSwitch = ()=>{
        if($("#cpselect").val() === '4'){
            $("#cpdiv").addClass("input-group");
            $("#cptext").show();
        }else{
            $("#cpdiv").removeClass("input-group");
            $("#cptext").hide();
        }
    }
    const handleSaveProfile = ()=>{
        console.log(tProfile?.pmis)
        console.log(uProfile?.pmis)
        let final = (uProfile?.pmis || tProfile?.pmis)
        console.log(final)
        if(tProfile?.pmis == "" || (!tProfile?.pmis && !uProfile?.pmis)){
                alert('Please Provide the required Fields \nPMIS is Compulsory')
                return;
        }




        var rawj = {
            "stateId": tProfile?.state_id || uProfile?.state_id,
            "districtId": tProfile?.district_id || uProfile?.district_id,
            "blockId": tProfile?.block_id || uProfile?.block_id,
            "educationQualification": tProfile?.education_qualification_id || uProfile?.education_qualification_id,
            "educationQualificationText": tProfile?.education_qualification_text || uProfile?.education_qualification_text,
            "totalExperience": tProfile?.total_experience || uProfile?.total_experience,
            "relevantExperienceType": tProfile?.relevant_experience_type || uProfile?.relevant_experience_type,
            "relevantExperience": tProfile?.relevant_experience || uProfile?.relevant_experience,
            "currentPosting": tProfile?.current_posting_id || uProfile?.current_posting_id,
            "currentPostingTest": tProfile?.current_posting_text || uProfile?.current_posting_text,
            "designation": tProfile?.designation || uProfile?.designation,
            "pmis": tProfile?.pmis || uProfile?.pmis,
        }
        console.log({tProfile,uProfile,rawj})
        // return;
        var myHeaders = new Headers();
        myHeaders.append("Authorization", localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify(rawj);
        console.log({rawj})
        // return;

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.editProfile, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                alertify.alert('eLearning',result.message).set('onok',()=>{
                    window.location.href='./dashboard'
                })

            })
            .catch(error => console.log('error', error));
    }






    return (
        <div>
            {/*{showMenu && <Overlay showMenu={setShowMenu}/>}*/}
            <div className="layout-sticky-subnav layout-default ">
                <div className="preloader">
                    <div className="sk-double-bounce">
                        <div className="sk-child sk-double-bounce1"></div>
                        <div className="sk-child sk-double-bounce2"></div>
                    </div>
                </div>
                <div className="mdk-header-layout js-mdk-header-layout">

                   <Header showMenu={setShowMenu}/>

                    <div className="mdk-header-layout__content page-content ">

                        <div className="pt-32pt">
                            <div
                                className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                                <div className="flex d-flex flex-column flex-sm-row align-items-center">

                                    <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                        <h2 className="mb-0">Profile</h2>
                                        <ol className="breadcrumb p-0 m-0">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">
                                                Edit Profile
                                            </li>
                                        </ol>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="container page__container page-section">

                            <div className="row mb-32pt">

                                <div className="col-lg-12 d-flex align-items-center">
                                    <div className="flex" style={{maxWidth:'100%'}}>


                                        <ProfilePicture/>
                                        <form action="./dashboard" className=" p-0 mx-auto">




                                            <div className="">
                                                <div className="form-row">
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label">Full name</label>
                                                        <input type="text" className="form-control" placeholder="Full name"
                                                               value={tProfile?.full_name}
                                                               onChange={(e)=>{
                                                                   setTP({...tProfile,full_name:e.target.value})
                                                               }}
                                                               disabled={Boolean(uProfile?.full_name)}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label" htmlFor="">Email</label>
                                                        <input type="text" className="form-control" id=""
                                                               placeholder="Enter Email" value={tProfile?.email}
                                                               onChange={(e)=>{
                                                                   setTP({...tProfile,email:e.target.value})
                                                               }}
                                                               disabled={Boolean(uProfile?.email)}/>
                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label" htmlFor="">Mobile No</label>
                                                        <input type="text" className="form-control" id=""
                                                               placeholder="Enter Mobile No"
                                                               value={tProfile?.mobile}
                                                               onChange={(e)=>{
                                                                   setTP({...tProfile,mobile:e.target.value})
                                                               }}
                                                               disabled={Boolean(uProfile?.mobile)}/>
                                                    </div>
                                                </div>


                                                <div className="form-row">


                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label">Highest Education Qualification</label>



                                                        <div className="input-group" id="eqgrpdiv">
                                                            <div className="input-group-prepend" id="iqselectdiv">


                                                                <select id="eqselect" value={tProfile?.education_qualification_id || uProfile?.education_qualification_id} className="form-control" placeholder="Education Qualified"
                                                                        onChange={(e)=>setTP({...tProfile,education_qualification_id:e.target.value})}>
                                                                    <option value="0">Select Education Qualification</option>
                                                                    {
                                                                        EduQuals.map((oneQual,I)=>{
                                                                            return <option value={oneQual?.edu_id} key={oneQual?.edu_id}>{oneQual?.edu_title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <input placeholder="Education Qualified" id="eqtext" type="text" className="form-control" onChange={(e)=>setTP({...tProfile,education_qualification_text:e.target.value})} value={tProfile?.education_qualification_text}/>

                                                        </div>




                                                    </div>

                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="">Years of Service</label>
                                                        <input value={tProfile?.total_experience} onChange={(e)=>setTP({...tProfile,total_experience:e.target.value})} type="text" className="form-control" id=""
                                                               placeholder="Years of Experience"/>
                                                    </div>


                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="">Relevant Experience<small>-In
                                                            Adolescent Health(Months / Years)</small></label>
                                                        <div className="input-group">

                                                            <div className="input-group-prepend">
                                                                <select className="form-control"
                                                                        placeholder="Education Qualified" onChange={(e)=>setTP({...tProfile,relevant_experience_type:e.target.value})} value={tProfile?.relevant_experience_type}>
                                                                    <option value="0">Select</option>
                                                                    <option value="1">Months</option>
                                                                    <option value="2">Year</option>
                                                                </select>
                                                            </div>
                                                            <input type="text" className="form-control" onChange={(e)=>setTP({...tProfile,relevant_experience:e.target.value})} value={tProfile?.relevant_experience}/>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <label className="form-label" htmlFor="">Current Posting</label>
                                                        <div className="input-group" id="cpdiv">
                                                            <div className="input-group-prepend">
                                                                <select id="cpselect" value={tProfile?.current_posting_id || uProfile.current_posting_id} onChange={(e)=>setTP({...tProfile,current_posting_id:e.target.value})} className="form-control" placeholder="Education Qualified">
                                                                    <option value="0">Select Posting</option>
                                                                    {
                                                                        Designations.map((oneDesi,I)=>{
                                                                            return <option value={oneDesi?.designation_id} key={oneDesi?.designation_id}>{oneDesi?.designation_title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <input type="text" id="cptext" className="form-control" value={tProfile?.current_posting_text} onChange={(e)=>setTP({...tProfile,current_posting_text:e.target.value})}/>
                                                        </div>

                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label" htmlFor="">Current Designation</label>
                                                        <input type="text" className="form-control" id=""  value={tProfile?.designation} onChange={(e)=>setTP({...tProfile,designation:e.target.value})}/>
                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label" htmlFor="">PMIS</label>
                                                        <input type="text" maxLength="5" className="form-control" id="pmis"  value={tProfile?.pmis} onChange={(e)=> {

                                                           let v = e.target.value.replace(/[^0-9]/g,"");
                                                            setTP({...tProfile, pmis: v})

                                                        }}/>
                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3"></div>
                                                   {/* <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label" htmlFor="">Photo</label>
                                                        <input type="file" className="form-control" id=""/>
                                                    </div>*/}




                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label">State</label>
                                                        <select className="form-control" placeholder="Education Qualified" value={tProfile.state_id || uProfile?.state_id} onChange={(e)=>handleStateChange(e.target.value)}>
                                                            <option value="0">Select State</option>
                                                            {
                                                                States?.map((oneState,I)=>{
                                                                    return <option value={oneState?.state_id} key={oneState?.state_id}>{oneState?.state_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label">District</label>
                                                        <select value={tProfile?.district_id || uProfile?.district_id} onChange={(e)=>handleDistrictChange(e.target.value)} className="form-control" placeholder="District">
                                                            <option value="0">Select District</option>
                                                            {
                                                                Districts?.map((oneDistrict,I)=>{
                                                                    return <option value={oneDistrict?.district_id} key={oneDistrict?.district_id}>{oneDistrict?.district_name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-md-4 mb-3">
                                                        <label className="form-label">Block</label>
                                                        <select className="form-control"
                                                                placeholder="Block"
                                                                value={tProfile?.block_id || uProfile?.block_id}
                                                                onChange={(e)=>setTP({...tProfile,block_id:e.target.value})}
                                                        >
                                                            <option value="0">Select Block</option>
                                                            {
                                                                Blocks?.map((oneBlock,I)=>{
                                                                    return <option value={oneBlock?.block_id} key={oneBlock?.block_id}>{oneBlock?.block_name}</option>
                                                                })
                                                            }
                                                        </select>

                                                    </div>



                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input className="custom-control-input" type="checkbox" value=""
                                                           id="invalidCheck01" required="" checked={agreed} onChange={()=>setAG(!agreed)}/>
                                                        <label className="custom-control-label" htmlFor="invalidCheck01">
                                                            I hereby declare, that all the above information is correct to the best of my knowledge
                                                        </label>
                                                </div>
                                            </div>
                                            <button disabled={!agreed} className="btn btn-primary" type="button" onClick={()=>handleSaveProfile()}>Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;