import React, {useState, useRef, useEffect} from 'react';
import API from "../Queries/API";
import {getProfileInfo,Checker} from '../Queries/Sessions';
const DefaultImage = require("../Assets/Images/profile.jpg")

function ProfilePicture() {
    const [imagePreview, setImagePreview] = useState(DefaultImage);
    const fileInputRef = useRef(null);
    const [uploadState, setUploadState]= useState('Change Image')


    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            previewFile(file);
        }
    };

    const previewFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setLoadedDefault(true)
        };
    };

    const handleSubmit = (e) => {
        // e.preventDefault();
        if (!imagePreview) return;
        uploadImage(imagePreview);
    };
    const [loadedDefault, setLoadedDefault] = useState(false)
    useEffect(()=>{
        if(loadedDefault){
            handleSubmit(imagePreview); // Automatically submit after setting the preview
        }
    },[imagePreview])

    const uploadImage = (base64EncodedImage) => {
        setUploadState('Uploading...')
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        // API call to upload the image
        fetch(API.ProfileImage, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({ data: base64EncodedImage }),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                setUploadState('Image Upload Success')
                // Handle success
            })
            .catch(error => {
                console.error(error);
                setUploadState('Failed to Upload')
                // Handle error
            });
    };

    useEffect(()=>{
        let y = getProfileInfo()
        if(y?.uid){
            setImagePreview(API.ImageStore+"/"+y?.uid+".png?"+Math.random())
        }
    },[])

    return (
        <form onSubmit={handleSubmit} style={{
            display:'flex',
            flexDirection:'column',
            marginBottom:10,
        }}>
            <img
                src={imagePreview || 'default-profile.png'}
                alt="Profile Preview"
                onClick={handleImageClick}
                style={{ height: '100px', width: '100px', cursor: 'pointer',borderRadius:20,borderWidth:0.5,boxShadow:'0px 0px 3px' }}
            />
            <input
                type="file"
                name="image"
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            <span style={{
                fontWeight:'bold',
                textDecoration:'underline',
                color:'grey',
                left:5,
                position:'relative',
                cursor:'pointer',
            }}
            onClick={handleImageClick}

            >{uploadState}</span>
        </form>
    );
}

export default ProfilePicture;
